import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { useCallback } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { sendCreateRequest } from "./RulesService";
import Checkbox from '@mui/material/Checkbox';
import { JsonEditor } from 'json-edit-react';
import RuleFieldPickedComponent from "./RuleFieldPickedComponent";
import MultiSelectComponent from '../MultiSelectComponent/MultiSelectComponent';
import { GetAllClients } from '../../Shared/HttpRequests';
import { isPositiveWholeNumber } from "../../Shared/UtillsJsFunctions";

export default function CreateRuleComponent() {
  const [ruleName, setRuleName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [category, setCategory] = useState("general");
  const [interval, setInterval] = useState("");
  const [ruleTemplate, setRuleTemplate] = useState("");
  const [jsonObj, setJsonObj] = useState({});
  const onAdd = () => {
    const cmdRuleFromJson = jsonObj;
    const intervalFormatted = interval !== "" ? +interval : null;
    const ruleTemplateFormatted = ruleTemplate !== "" ? +ruleTemplate : null;
    const dataObj = {
      name: ruleName,
      description: description,
      active: isActive,
      cmd: cmdRuleFromJson, //rule,
      category: category || "general",
      cmd_templates: ruleTemplateFormatted,
      cycle: intervalFormatted,
    };
    sendCreateRequest(dataObj).then(
      response => {
      })
      .catch(error => {
        console.log('rule : Error:', error);
      }).finally(() => {
        window.location.reload();
      });
  }
  const createHandlerFunctionAdd = (topLevelField) => {
    return (jsonFieldName, jsonFieldVal) => {
      const newJsonObj = { ...jsonObj };
      if (!jsonFieldVal) {
        return;
      }
      if (!newJsonObj[topLevelField]) {
        newJsonObj[topLevelField] = [];
      }
      if (jsonFieldName === "re : stdout" || jsonFieldName === "re : stderr") {
        let fieldName = "stdout";
        const regexField = "re";
        if (jsonFieldName === "re : stdout") {
          fieldName = "stdout";
        } else if (jsonFieldName === "re : stderr") {
          fieldName = "stderr";
        }
        newJsonObj[topLevelField].push({ [regexField]: { [fieldName]: jsonFieldVal } });
      } else {
        newJsonObj[topLevelField].push({ [jsonFieldName]: jsonFieldVal });
      }
      if (dists && dists.length > 0) {
        const lastIndex = newJsonObj[topLevelField].length - 1;
        Object.assign(newJsonObj[topLevelField][lastIndex], { ["distribution"]: dists });
      }
      if (systems && systems.length > 0) {
        const lastIndex = newJsonObj[topLevelField].length - 1;
        Object.assign(newJsonObj[topLevelField][lastIndex], { ["system"]: systems });
      }
      setJsonObj(newJsonObj);
    }
  }
  const createHandlerFunctionDelete = (topLevelField) => {
    return (jsonFieldName, jsonFieldVal) => {
      const newJsonObj = { ...jsonObj };
      const index = newJsonObj[topLevelField].findIndex(el => el[jsonFieldName] === jsonFieldVal);
      if (index > -1) { // only splice array when item is found
        newJsonObj[topLevelField].splice(index, 1); // 2nd parameter means remove one item only
      }
      setJsonObj(newJsonObj);
    }
  }
  const tableFieldKindNameValidation = "validation";
  const handleAddedValidationRule = createHandlerFunctionAdd(tableFieldKindNameValidation);
  const handleDeleteValidationRule = createHandlerFunctionDelete(tableFieldKindNameValidation);
  const tableFieldKindNameExepct = "expect";
  const handleAddedExpect = createHandlerFunctionAdd(tableFieldKindNameExepct);
  const handleDeleteExpect = createHandlerFunctionDelete(tableFieldKindNameExepct);
  const tableFieldKindNameRemedy = "remedy";
  const handleAddedRemedy = createHandlerFunctionAdd(tableFieldKindNameRemedy);
  const handleDeleteRemedy = createHandlerFunctionDelete(tableFieldKindNameRemedy);
  const optionsFieldValidation = [
    { "value": "command" },
    { "value": "base64" },
    { "value": "uri_sh" },
    { "value": "function" },
  ];
  const optionsFieldExepct = [
    { "value": "re : stdout" },
    { "value": "re : stderr" },
    { "value": "returncode" },
    { "value": "md5" },
  ];
  const optionsFieldRemedy = [
    { "value": "command" },
    { "value": "base64" },
    { "value": "uri_sh" },
    { "value": "function" },
  ];
  const preLoadDataValidation = jsonObj[tableFieldKindNameValidation];
  const preLoadDataExepct = jsonObj[tableFieldKindNameExepct];
  const preLoadDataRemedy = jsonObj[tableFieldKindNameRemedy];
  const generatedKey = (el, index, topLevelField) => {
    const fieldKey = Object.keys(el)[0];
    const generatedKey = `${fieldKey}${el[fieldKey]}${index}${topLevelField}`;
    return generatedKey;
  }
  const [dists, setDists] = useState([]);
  const [systems, setSystems] = useState([]);
  const updateDists = (event) => {
    const arr = event.map(el => el.label);
    setDists(arr);
    const fieldName = "distribution";
    const newJsonObj = { ...jsonObj };
    setJsonObj(newJsonObj);
  };
  const updateSystems = (event) => {
    console.log("Update from updateSystems , event : ", event);
    const arr = event.map(el => el.label);
    setSystems(arr);
    const fieldName = "system";
    const newJsonObj = { ...jsonObj };
    newJsonObj[fieldName] = arr;
    setJsonObj(newJsonObj);
  };
  const [distNames, setDistNames] = useState([]);
  const [systemsOptions, setSystemsOptions] = useState([]);
  const apiCallAndDataTransform = () => {
    GetAllClients().then(response => {
      const dataDistName = MakeUniqueArrayField(response.map((el) => { return { label: el.info.dist.name, value: el.id } }), "label");
      const dataSystem = MakeUniqueArrayField(response.map((el) => { return { label: el.info.uname.system, value: el.id } }), "label");
      setDistNames(dataDistName);
      setSystemsOptions(dataSystem);
    })
      .catch(error => {
        console.error("Error fetching data:", error);
      }).finally(() => {
      });
  }
  React.useEffect(() => {
    apiCallAndDataTransform();
  }, []);
  const handleIntervalInput = (e) => {
    const inputTxt = e.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setInterval(inputTxt);
      const checkIfEmpty = inputTxt.trim() === "";
      setSaveEnabled(checkIfEmpty);
    }
  }
  const MakeUniqueArrayField = (elements, key) => {
    const seen = new Set();
    const ary = [];
    elements.forEach(item => {
      const el = item[key];
      const duplicate = seen.has(el);
      if (!duplicate) {
        ary.push({ "value": item.value, "label": el });
        seen.add(el);
      }
    })
    return ary;
  };
  const [saveEnabled, setSaveEnabled] = useState(false);
  return (
    <Card sx={{ width: 800 }}>
      <Box sx={{ width: '100%', padding: 0 }}  >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} rowSpacing={0}>
            <Box sx={{ display: 'flex', justifyContent: "center" }} >
              <h3>Create Rule</h3>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: "left", marginLeft: 10 }}  >
              <h4 > Active : </h4>
              <Checkbox
                defaultChecked={isActive}
                onChange={() => setIsActive(!isActive)}
              ></Checkbox>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button onClick={onAdd} disabled={saveEnabled}> <SaveIcon /> </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box  >
              <Box sx={{ marginLeft: 4 }}>
                <h4 > Name  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Name"
                  sx={{ width: 300 }}
                  onChange={(e) => setRuleName(e.target.value)}
                  value={ruleName}
                />
              </Box>
            </Box >
          </Grid>
          <Grid item xs={6}>
            <Box >
              <Box sx={{ marginLeft: 4 }}>
                <h4 > Description  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Description"
                  sx={{ width: 300 }}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </Box>
            </Box >
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box  >
              <Box >
                <h4 > Category  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Category"
                  sx={{ width: 300 }}
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                />
              </Box>
            </Box >
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box  >
              <Box >
                <h4 > Template  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Template"
                  sx={{ width: 300 }}
                  onChange={(e) => setRuleTemplate(e.target.value)}
                  value={ruleTemplate}
                />
              </Box>
              <Box> <p> * need to be a number </p> </Box>
            </Box >
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box  >
              <Box >
                <h4 > Interval  </h4>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <TextField
                  placeholder="Interval"
                  sx={{ width: 300 }}
                  onChange={(e) => handleIntervalInput(e)}
                  value={interval}
                />
              </Box>
              <Box> <p> * need to be a number </p> </Box>
            </Box >
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box  >
            </Box >
          </Grid>
          <Grid item xs={12} sx={{ margin: 0 }} ></Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', height: 200 }} >
            <MultiSelectComponent
              updateData={updateDists}
              title={"Distribution"}
              options={distNames}
              handleClearSelection={() => { }}
              tryField={"Trying!..."}
            ></MultiSelectComponent>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', height: 200 }} >
            <MultiSelectComponent
              updateData={updateSystems}
              title={"System"}
              options={systemsOptions}
            ></MultiSelectComponent>
          </Grid>
          <RuleFieldPickedComponent
            toplevelField={tableFieldKindNameValidation}
            options={optionsFieldValidation}
            preLoadData={preLoadDataValidation}
            handleDeleteRule={handleDeleteValidationRule}
            handleAddRule={handleAddedValidationRule}
            generatedKey={generatedKey}
          ></RuleFieldPickedComponent>
          <RuleFieldPickedComponent
            toplevelField={tableFieldKindNameExepct}
            options={optionsFieldExepct}
            preLoadData={preLoadDataExepct}
            handleDeleteRule={handleDeleteExpect}
            handleAddRule={handleAddedExpect}
            generatedKey={generatedKey}
          ></RuleFieldPickedComponent>
          <RuleFieldPickedComponent
            toplevelField={tableFieldKindNameRemedy}
            options={optionsFieldRemedy}
            preLoadData={preLoadDataRemedy}
            handleDeleteRule={handleDeleteRemedy}
            handleAddRule={handleAddedRemedy}
            generatedKey={generatedKey}
          ></RuleFieldPickedComponent>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
            <Box >
              <Box >
                <h4 > Rule Json Editor  </h4>
              </Box>
              <Box> <p> json edit </p> </Box>
              <JsonEditor
                rootName="rule"
                minWidth={400}
                data={jsonObj}
                onUpdate={({ newData }) => {
                  setJsonObj(newData);
                }}
              />
            </Box >
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
