import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import { GetSearchHosts } from '../../Shared/HttpRequests';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchPopover(prop) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const idNullableObj = { id: null, hasId: false };
    const handleAutocompleteChange = (val, field) => {
        const item = seen.get(val);
        const itemId = item ? item.id : -1;
        prop.filteringByValue(val, field, itemId);
    };
    const [fieldToFilter, setFieldToFilter] = useState("name");
    const [dataSearch, setDataSearch] = useState([]);
    const titleMessage = "Search By Host";
    const handleSyncData = prop.handleSyncData;
    const [dictKeyVal, setDictKeyVal] = useState();
    const [seen, setSeen] = useState(new Map());
    const MakeUniqueArrayField = (elements, key) => {
        const activeSeenMap = new Map();
        const ary = [];
        elements.forEach(item => {
            const el = item[key];
            const duplicate = activeSeenMap.has(el);
            if (!duplicate) {
                ary.push(el);
                activeSeenMap.set(el, item);
            }
        })
        setSeen(activeSeenMap);
        return ary;
    };

    const apiCall = () => {
        const key = "name";
        GetSearchHosts().then(response => {
            const transformedDataAry = MakeUniqueArrayField(response, key);
            setDataSearch(transformedDataAry);
        }
        ).catch(error => {
            console.log(error)
        }).finally(() => {
        });
    }
    useEffect(() => {
        apiCall();
    }, []);

    return (
        <div>
            <>
                <IconButton variant="outlined" size="small" color='primary' onClick={handleClick} >
                    <SearchIcon />
                </IconButton>
            </>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ display: 'flex' }}>

                    <Typography sx={{ p: 2 }}> {titleMessage}  </Typography>

                    <IconButton variant="outlined" size="small" color='primary' onClick={handleSyncData} >
                        <SyncIcon />
                    </IconButton >

                </div>

                <Autocomplete sx={{ width: 320, p: 2 }}
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={dataSearch}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search input"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}

                        // value={setValuePicked}
                        // inputValue={ val => setValuePicked(val)}

                        // onChange={ val => setValuePicked(val)}
                        />


                    )}

                    // onChange={ val => setValuePicked(val)}
                    onChange={(_, val) => { handleAutocompleteChange(val, fieldToFilter); }}
                // inputValue={valueSearchBox}

                // value={setValuePicked}


                />



            </Popover>
        </div >
    );
}
