import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TablePagination from '@mui/material/TablePagination';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';
import SearchPopoverServer from '../SearchPopover/SearchPopoverServer';
import ColumnSelectorPopover from '../Popover Columns/PopoverComponentColumns';

import { fomattedTimeDisplay, logIfTsPassedNDays } from '../../Shared/UtillsJsFunctions';
import { shortenString } from '../../Shared/UtillsJsFunctions';
import { ColoringValidationSettings } from '../../Shared/ThemesColor';
import { SortState } from "../../Shared/UtillsJsFunctions";
import { functionChangeElement } from '../../Shared/FunctionChange';

import {
  GetCommandResultsByClientId,
  GetCommandResultsByClientAndRuleIDs,
  GetCommandResultsByCmdId,
  GetCommandResultsByGroup,
  GetCommandResultsSortingNew
} from "./ExecutionRequests";

import { GetSettingsUi, UpdateSettings } from '../../Shared/HttpRequests';
import { useQuery } from '@tanstack/react-query';

function createTransformObjectData(item, inactiveTime, mainRowDetails = true) {
  const clientName = item.client_name || "";
  const commandName = item.cmd_name || "";
  const stdout = JSON.stringify(item.result.stdout) || "";
  const stderr = item.result.stderr || "";
  const returncode = item.result.returncode;
  const timeFormattedTs = fomattedTimeDisplay(item.ts);
  const clientId = item.clients_id;
  const cmdId = item.cmd_id;
  const id = mainRowDetails ? `${clientId}-${cmdId}` : item.id;
  const verified = logIfTsPassedNDays(item.ts, item.verified, inactiveTime) || item.verified;
  const cmdType = item.cmd_type || "--";
  const cmd_results_id = item.cmd_results_id || null;

  const distribution = item.distribution || "dist null";
  const version = item.version || "0.0.0.0 ver";
  return {
    verified,
    id,
    name: clientName,
    rule: commandName,
    stdout,
    stderr,
    returncode,
    type: cmdType,
    time: timeFormattedTs,
    cmd_results_id,
    clientId,
    cmdId,
    ts: item.ts,
    changed: item.changed,
    distribution : distribution,
    version : version
  };
}

function MergeValidationWithSource(detailsResults) {
  const merged = [];
  detailsResults.forEach(element => {
    if (element.type !== "source" && element.type !== "remedy") return;
    const foundElement = detailsResults.find(item => item.cmd_results_id === element.id);
    if (!foundElement) return;
    element.verified = foundElement.verified;
    merged.push(element);
  });
  if (merged.length === 0 && detailsResults.length > 0) {
    return detailsResults;
  }
  return merged;
}

function Row(props) {
  const {
    row,
    isExpanded,
    onToggleExpand,
    subDetails = [],
    inactive_status_color = "gold",
    stdoutShortenNumber = 32,
    stderrShortenNumber = 32,
    visibleColumns = [],
    stdoutSubShortenNumber = 32,
    stderrSubShortenNumber = 32,
  } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("");
  const [modalTitle, setModalTitle] = React.useState("");

  const handleOpenModal = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent("");
    setModalTitle("");
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 0  }}> 
          <Box sx={ColoringValidationSettings(row.verified, inactive_status_color)} />
        </TableCell>

        <TableCell sx={{ padding: 0 , margin: 0, width: 40}}>
          <IconButton size="small" style={{width: 40}} onClick={() => onToggleExpand(row.id)}>
            {isExpanded ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>

        {visibleColumns.includes("client_name") && (
          <TableCell sx={{ padding: 0 }}> {row.name}</TableCell>
        )}

        {visibleColumns.includes("cmd_name") && (
          <TableCell sx={{ paddingLeft: 0 }}>{row.rule}</TableCell>
        )}

        {visibleColumns.includes("result->>stdout") && (
        <TableCell sx={{ padding: 0 }}
            onClick={() => handleOpenModal(row.stdout, `stdout - ${row.name} ${row.rule} @ ${row.time}`)}
        >
          {shortenString(row.stdout, stdoutShortenNumber)}
        </TableCell>
        )}

        {visibleColumns.includes("result->>stderr") && (
          <TableCell sx={{ padding: 0 }}
            onClick={() => handleOpenModal(row.stderr, `stderr - ${row.name} ${row.rule} @ ${row.time}`)}
          >
            {shortenString(row.stderr, stderrShortenNumber)}
          </TableCell>
        )}

   
        {visibleColumns.includes("result->>returncode") && (
          <TableCell sx={{ padding: 0 }}>{row.returncode}</TableCell>
        )}

        {visibleColumns.includes("ts") && (
          <TableCell sx={{ padding: 0 }}>{row.time}</TableCell>
        )}

          {visibleColumns.includes("distribution") && (
          <TableCell  sx={{ padding: 0 ,margin: 0 }} >{row.distribution}</TableCell>
        )}

          {visibleColumns.includes("version") && (
          <TableCell sx={{ padding: 0 ,margin: 0 ,width : 25}} >{row.version}</TableCell>
        )}
        
      </TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
      <TableRow style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ marginLeft: 2, marginBottom: 2 }}>
            <Box sx={{ margin: 0 }}>
              <Table aria-label="subTable">
                <TableBody>
                <TableCell sx={{ marginTop: 6}} ></TableCell>
                    
                     <TableCell
                            key={"01234567"}
                            sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 }}
                            onClick={() => {}}
                            align='left'
                            >

                            <Box sx={{ display: 'flex', width: 160 }}>
                              <Box>{"Stdout"}</Box>
                          </Box>
                      </TableCell>

                     <TableCell
                            key={"01234567"}
                            sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 }}
                            onClick={() => {}}
                            align='left'
                            >

                            <Box sx={{ display: 'flex', width: 160 }}>
                              <Box>{"Stderr"}</Box>
                          </Box>
                      </TableCell>

                     <TableCell
                            key={"01234567"}
                            sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 }}
                            onClick={() => {}}
                            align='left'
                            >

                            <Box sx={{ display: 'flex', width: 160 }}>
                              <Box>{"Return Code"}</Box>
                          </Box>
                      </TableCell>
                   
                     <TableCell
                            key={"01234567"}
                            sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 }}
                            onClick={() => {}}
                            align='left'
                            >

                            <Box sx={{ display: 'flex', width: 160 }}>
                              <Box>{"Time"}</Box>
                          </Box>
                      </TableCell>

                      <TableCell></TableCell>
                      <TableCell></TableCell>

                  {subDetails.map((drow) => (
                    <TableRow key={drow.id}>
                      <TableCell sx={{ padding: 0 }}>
                        <Box sx={ColoringValidationSettings(drow.verified, inactive_status_color)} />
                      </TableCell>
                      <TableCell sx={{ padding: 0, width: 220 }} onClick={() => handleOpenModal(drow.stdout, `stdout - ${row.name} ${row.rule} @ ${drow.time}`)} align='left'>{shortenString(drow.stdout, stdoutSubShortenNumber)}</TableCell>
                      <TableCell sx={{ padding: 0, width: 220 }} onClick={() => handleOpenModal(drow.stderr, `stderr - ${row.name} ${row.rule} @ ${drow.time}`)} align='left'>{shortenString(drow.stderr, stderrSubShortenNumber)}</TableCell>
                        <TableCell sx={{  padding: 0,width: 165 }}>{drow.returncode}</TableCell>
                        <TableCell sx={{  padding: 0, paddingLeft: 3, width: 200 }}>{drow.time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, fontSize: 'x-small' }}
              >
                <p> total rows : {subDetails.length} </p>
              </Box>
            </Box>
          </Collapse>
      </TableRow>
        </TableCell>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-content"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: 4,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h3 id="modal-title">{modalTitle}</h3>
          <p id="modal-content" style={{ whiteSpace: "pre-wrap" }}>
            {modalContent.replace(/\\n/g, "\n").replace(/\\t/g, "\t")}
          </p>
          <Button onClick={handleCloseModal}>Close</Button>
        </Box>
      </Modal>

    </>
  );
}

function transformAndWrap(rawData, inactiveTime) {
  if (!Array.isArray(rawData)) return [];
  return rawData.map((item) => {
    return createTransformObjectData(item, inactiveTime);
  });
}

async function fetchExecutionData({ queryParams, page, rowsPerPage, inactiveTime }) {
  const offset = page * rowsPerPage;
  const { field, asc } = sortState.getSort();

  if (queryParams.clientsIds && queryParams.cmdsIds) {
    return await GetCommandResultsByGroup(queryParams.clientsIds, queryParams.cmdsIds, field, asc, offset, rowsPerPage);
  } else if (queryParams.client_id) {
    const clientId = +queryParams.client_id;
    return await GetCommandResultsByClientId(clientId, field, asc, offset, rowsPerPage);
  } else if (queryParams.cmd_id) {
    const cmdId = +queryParams.cmd_id;
    return await GetCommandResultsByCmdId(cmdId, field, asc, offset, rowsPerPage);
  } else {
    return await GetCommandResultsSortingNew(field, asc, offset, rowsPerPage);
  }
}

const colsHeaderNames = [
  { title: "Host Name", fieldName: "client_name" },
  { title: "Rule", fieldName: "cmd_name" },
  { title: "Stdout", fieldName: "result->>stdout" },
  { title: "Stderr", fieldName: "result->>stderr" },
  { title: "Return Code", fieldName: "result->>returncode" },
  { title: "Time", fieldName: "ts" },
  { title: "Distribution", fieldName: "distribution" },
  { title: "Version", fieldName: "version" },
];

const defaultAsc = true;
const indexTsOfColsHeaderNames = 5;
const initSortField = "ts";
const sortState = new SortState(initSortField, !defaultAsc);
const LOCAL_KEY_PAGE = 'execution_page';
const LOCAL_KEY_ROWS_PER_PAGE = 'execution_rows_per_page';


export default function CollapsibleTable() {
  functionChangeElement.setNewAction(() => {
    window.location.reload();
  });

  const getInitialVisibleColumns = () => {
    return colsHeaderNames.map((col) => col.fieldName);
  };
  
  const [visibleColumns, setVisibleColumns] = useState( getInitialVisibleColumns );


  const handleColumnsChange = (selectedFields) => {
    setVisibleColumns(selectedFields);
  };

  const [searchParams] = useSearchParams();

  const queryParamsRef = useRef({});
  for (const [key, value] of searchParams.entries()) {
    queryParamsRef.current[key] = value;
  }

  const [totalCount, setTotalCount] = useState(20);
  const [page, setPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_PAGE), 0) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_ROWS_PER_PAGE), 10) || 10);
  const [inactiveStatusColor, setInactiveStatusColor] = useState("red");
  const [inactiveTime, setInactiveTime] = useState(14);

  const [stdoutShortenNumber, setStdoutShortenNumber] = useState(32);
  const [stderrShortenNumber, setStderrShortenNumber] = useState(32);

  const [stdoutSubShortenNumber, setStdoutSubShortenNumber] = useState(32);
  const [stderrSubShortenNumber, setStderrSubShortenNumber] = useState(32);


  const [numberRowsExecution, setNumberRowsExecution] = useState(200);
  const [dataClients, setDataClients] = useState([]);
  const [isLoadingManual, setIsLoadingManual] = useState(false);
  const [rowExpansionMap, setRowExpansionMap] = useState({});
  const [showChangedToast, setShowChangedToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info"); 
  const changedRecordsMap = useRef({});
  useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();
        const settings_ui_Obj = settings_ui_Obj_data_list[0]?.settings || {};

        setInactiveTime(settings_ui_Obj.execution_inactive_time ?? 14);
        setInactiveStatusColor(settings_ui_Obj.execution_inactive_status_color ?? "red");
        setStdoutShortenNumber(settings_ui_Obj.stdout_shorten_number ?? 32);
        setStderrShortenNumber(settings_ui_Obj.stderr_shorten_number ?? 32);

        setStdoutSubShortenNumber(settings_ui_Obj.stdout_sub_shorten_number ?? 32);
        setStderrSubShortenNumber(settings_ui_Obj.stderr_sub_shorten_number ?? 32);

        setNumberRowsExecution(settings_ui_Obj.number_rows_execution ?? 200);

       
      } catch (error) {
        console.error("[CollapsibleTable] error fetching UI settings:", error);
      }
    };
    initializeSettings();
  }, []);

  async function fetchDataQuery() {
    const results = await fetchExecutionData({
      queryParams: queryParamsRef.current,
      page,
      rowsPerPage,
      inactiveTime
    });

    const changedEntries = results.data.filter(el => el.changed === true);
    let showToast = false;
    let changedElement = null;

    for (const ch of changedEntries) {
      const key = `${ch.clients_id}_${ch.cmd_id}`;
      const currentTs = changedRecordsMap.current[key];

      if (!currentTs || currentTs !== ch.ts) {
        changedRecordsMap.current[key] = ch.ts; 
        showToast = true;
        changedElement = ch;
      }
    }

      if (showToast && changedElement) {
  let toastMsg = (
    <span>
      Host{' '}
      <a href={`/execution?client_id=${changedElement.clients_id}`} target="_blank" rel="noopener noreferrer">
        {shortenString(changedElement.client_name, 32)}
      </a>{' '}
      verification{' '}
      {changedElement.verified === false ? 'failed' : 'succeeded'}
    </span>
  );

  setSnackbarSeverity(changedElement.verified === false ? 'error' : 'success');
  setSnackbarMessage(toastMsg);
  setShowChangedToast(true);
}

return results;
  }

  const { data: queryResult, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'executionData',
      queryParamsRef.current,
      page,
      rowsPerPage,
      sortState.getSort().field,
      sortState.getSort().asc,
      inactiveTime
    ],
    queryFn: fetchDataQuery,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    refetchIntervalInBackground: true
  });

  useEffect(() => {
    if (!isLoading && !isError && queryResult?.data) {
      const { data: rawData, count } = queryResult;
      setTotalCount(count ?? 0);

      const transformed = transformAndWrap(rawData, inactiveTime);
      setDataClients(transformed);

      setRowExpansionMap((prevMap) => {
        const newMap = { ...prevMap };
        for (const rowId of Object.keys(newMap)) {
          if (!transformed.some((r) => r.id === Number(rowId))) {
            delete newMap[rowId];
          }
        }
        return newMap;
      });
    }
  }, [queryResult, isLoading, isError, inactiveTime]);

  const handleToggleExpand = async (rowId) => {
    setRowExpansionMap((prev) => {
      const prevItem = prev[rowId];
      const isOpen = !!prevItem?.open;
      const newMap = { ...prev };
      if (!isOpen) {
        newMap[rowId] = { open: true, details: prevItem?.details || [] };
        fetchSubDetails(rowId);
      } else {
        newMap[rowId] = { ...prevItem, open: false };
      }
      return newMap;
    });
  };

  const fetchSubDetails = async (rowId) => {
    try {
      const mainRow = dataClients.find((r) => r.id === rowId);
      if (!mainRow) return;
      const mainRowDetails = false;
      const arrDetails = await GetCommandResultsByClientAndRuleIDs(mainRow.clientId, mainRow.cmdId, numberRowsExecution);
      const detailsResults = arrDetails.data.map((item) => createTransformObjectData(item, 14, mainRowDetails));
      const merged = MergeValidationWithSource(detailsResults);

      setRowExpansionMap((prev) => {
        const newMap = { ...prev };
        if (!newMap[rowId]) newMap[rowId] = { open: true, details: merged };
        else newMap[rowId].details = merged;
        return newMap;
      });
    } catch (error) {
      console.error("[fetchSubDetails] error fetching details:", error);
    }
  };

  const getSubDetailsForRow = (rowId) => rowExpansionMap[rowId]?.details || [];
  const isRowExpanded = (rowId) => !!rowExpansionMap[rowId]?.open;
  const handleClickedHeader = (index) => {
    const keyField = colsHeaderNames[index].fieldName;
    if (sortState.field === keyField) {
      sortState.setSort(keyField, !sortState.asc);
    } else {
      sortState.setSort(keyField, true);
    }
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = async (event) => {
    const limit = +event.target.value;
    const offset = 0;

    setPage(0);
    setRowsPerPage(limit);
    localStorage.setItem(LOCAL_KEY_PAGE, offset);
    localStorage.setItem(LOCAL_KEY_ROWS_PER_PAGE, limit);

  };

  const handleSyncData = async () => {
    refetch({ exact: true });
  };

  const filteringByValue = (value, key, id) => {
    setIsLoadingManual(true);
    setDataClients([]);
    setPage(0);

    queryParamsRef.current.client_id = id;
    delete queryParamsRef.current.cmd_id;
    delete queryParamsRef.current.cmdsIds;
    delete queryParamsRef.current.clientsIds;

    refetch().finally(() => setIsLoadingManual(false));
  };

  const loadingOverall = isLoading || isError || isLoadingManual;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowChangedToast(false);
  };

  return (
    <Card>
      <CardContent>
        {loadingOverall ? (
          isError ? (
            <p style={{ color: 'red' }}>Error fetching data</p>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <GradientCircularProgress />
            </Box>
          )
        ) : (
          <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                       <TableCell sx={{ paddingRight : 3 , marginRight : 50 , width: 10 , backgroundColor: "#ffffff" , alignSelf: "left"}} colSpan={2}  align='left' >
                       <Box  sx={{ paddingRight : 1 , width: 10 ,align: "left"  , backgroundColor: "transparent" }} align='left'  >
                        <ColumnSelectorPopover align='left'  style={{ backgroundColor: "green"}}
                          columns={colsHeaderNames}
                          onColumnsChange={handleColumnsChange}
                        />

                        </Box>
                       </TableCell>
                      {colsHeaderNames.map((colItem, index) => {
                        if (!visibleColumns.includes(colItem.fieldName)  ) {
                          return null;
                        }

                        const { field, asc } = sortState.getSort();
                        const isSorted = field === colItem.fieldName;

                        return (
                          <TableCell
                            key={colItem.fieldName}
                            sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 , margin: 0}}
                            onClick={() => handleClickedHeader(index)}
                          >
                            <Box sx={{ display: 'flex', width: 90 }}>
                              <Box>{colItem.title}</Box>
                              <Box>
                                {isSorted ? (asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />) : null}
                              </Box>
                            </Box>
                          </TableCell>
                        );
                      })}
                      <TableCell sx={{ padding: 0, margin: 0, width: 50}} >
                        <SearchPopoverServer
                          filteringByValue={filteringByValue}
                          handleSyncData={handleSyncData}
                          />
                      </TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataClients.map((row) =>   (
                      <Row
                        key={row.id}
                        row={row}
                        isExpanded={isRowExpanded(row.id)}
                        onToggleExpand={handleToggleExpand}
                        subDetails={getSubDetailsForRow(row.id)}
                        inactive_status_color={inactiveStatusColor}
                        stdoutShortenNumber={stdoutShortenNumber}
                        stderrShortenNumber={stderrShortenNumber}
                        stdoutSubShortenNumber = { stdoutSubShortenNumber }
                        stderrSubShortenNumber = { stderrSubShortenNumber }
                        visibleColumns={visibleColumns}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        )}
      </CardContent>
      <Snackbar
        open={showChangedToast}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
}
