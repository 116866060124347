import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

export default function ConfirmDialog(props) {
  const { onClose, open } = props;

  const handleClose = (response) => {
    onClose(response);
    console.log(" A L ")
  };

  const hostname = props.hostname || "";

  return (
    <Dialog onClose={() => handleClose('No')} open={open}
    slotProps={{
      backdrop: { 'aria-hidden': 'false' }, // Use slotProps to set aria-hidden
    }}
    >
      <DialogTitle>Are You Sure You Want To Delete:</DialogTitle>
      <h4 style={{ margin: 25 }}>{hostname}</h4>
      <DialogActions>
        <Button onClick={() => handleClose('No')} color="primary">
          No
        </Button>
        <Button onClick={() => handleClose('Yes')} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function ConfirmDialogDemo() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (response) => {
    setOpen(false);
    console.log('User response:', response);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open Confirm Dialog
      </Button>
      <ConfirmDialog open={open} onClose={handleClose} />
    </div>
  );
}




export { ConfirmDialogDemo };