
import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';
import { GetCommandResultsDashboard } from '../../Shared/HttpRequests';
import Box from '@mui/material/Box';

const size = {
  width: 480,
  height: 340,
};

export default function PieArcLabel(props) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  const picked_cmd_name = props.picked_cmd_name || "Host Name"; // Selected command name
  
  // State for PieChart data
  const [pieData, setPieData] = useState([]);

  const loadData = async () => {
    try {
      const data = await GetCommandResultsDashboard('name', true, 0, 10);
      setResults(data);


      // Filter data by picked command name
      const filteredData = data.filter((el) => el.cmd_name === picked_cmd_name);

      // Group data by "Host Name" with active and inactive counts
      const hostCounts = filteredData.reduce(
        (acc, item) => {
          const hostName = item.host_name || 'Unknown'; // Handle missing host names
          const isActive = item.verified; // Check for active status

          if (!acc[hostName]) {
            acc[hostName] = { active: 0, inactive: 0 };
          }

          if (isActive) {
            acc[hostName].active += 1; // Increment active count
          } else {
            acc[hostName].inactive += 1; // Increment inactive count
          }

          return acc;
        },
        {}
      );

      // Prepare pie chart data (separating active and inactive hosts)
      const chartData = Object.entries(hostCounts).flatMap(([hostName, counts]) => [
        {
          value: counts.active,
          label: `compliant: ${counts.active}`,
          // label: `${hostName} (compliant: ${counts.active})`,
          // value: hostCounts.active,
          color: activeBarColor,
        },
        {
          value: counts.inactive,
          label: `non compliant: ${counts.inactive}`,
          // label: `${hostName} (non compliant: ${counts.inactive})`,
          // value: hostCounts.inactive,
          color: notActiveBarColor,
        },
      ]);

      setPieData(chartData); // Update PieChart data

    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    loadData(); // Fetch data on component mount
  }, [picked_cmd_name]); // Reload if picked_cmd_name changes

  return (
    <>
      <Box sx={{ width: 500, height: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'left', marginLeft: 12 }}>
          <h3> {picked_cmd_name} </h3>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart
            series={[
              {
                arcLabel: (item) => `(${item.value})`,
                arcLabelMinAngle: 15,
                data: pieData, // Updated PieChart data
                innerRadius: 40,
                outerRadius: 120,
                paddingAngle: 0,
                cornerRadius: 5,
                startAngle: 0,
                endAngle: 360,
                cx: 150,
                cy: 150,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontWeight: 'bold',
              },
            }}
            {...size}
          />
        </Box>

        {error && <p>Error: {error}</p>}
      </Box>
    </>
  );
}
