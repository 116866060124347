import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";


import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Card, CardContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import CreateGroupData from './CreateGroupComponent';
import EditGroupData from './EditGroupComponent';
import { shortenString } from '../../Shared/UtillsJsFunctions';

import TablePagination from '@mui/material/TablePagination';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';


import { functionChangeElement } from '../../Shared/FunctionChange';


import { GetGroupsSorting } from './groupsRequests';

import ListIcon from '@mui/icons-material/List';


import { GetSettingsUi } from '../../Shared/HttpRequests';
import { UpdateSettings } from '../../Shared/HttpRequests.js';




function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const EditAction = (row) => {
    if (props.onEditClick) {
      props.onEditClick();
    }
  }


  const nav = useNavigate();

  // queryParams.clientsIds && queryParams.cmdsIds



  const redirectToExecution = () => {

    const clientsIds = row.clients_key_values.map(el => el.id).join(",");
    const cmdsIds = row.cmds_key_values.map(el => el.id).join(",");
    const restRedirectUrl = `/execution?clientsIds=(${clientsIds})&cmdsIds=(${cmdsIds})`;
    nav(restRedirectUrl);
  };

  const redirectToClients = () => {

    const clientsIds = row.clients_key_values.map(el => el.id).join(",");
    // const cmdsIds = row.cmds_key_values.map(el => el.id).join(",");

    const restRedirectUrl = `/compliancy?clientsIds=(${clientsIds})`;
    nav(restRedirectUrl);
  };



  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}   >

        <TableCell sx={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" sx={{ padding: 0, margin: 0, width: 20 }} align="left" >
          <Box sx={{ marginLeft: 2 }} >
            {row.is_active ? <CheckIcon></CheckIcon> : null}
          </Box>
        </TableCell>

        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }}  >
          <Box >
            {row.name}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{shortenString(row.clients ? row.clients.join(" ,") : "--")}</TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{shortenString(row.cmds ? row.cmds.join(" ,") : "--")}</TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.description} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >
          <Button sx={{ width: 10, height: 5 }} startIcon={<EditIcon></EditIcon>} onClick={() => EditAction(row)}>

          </Button>

        </TableCell>

        <TableCell align="center" sx={{ width: 0, padding: 0 }}>
          <Button onClick={redirectToExecution} >
            <ListIcon sx={{ marginRight: 3 }}></ListIcon>
          </Button>
        </TableCell>
       
       {/* BM : new to have forward to clients page see all clients of this group */}
        <TableCell align="center" sx={{ width: 0, padding: 0 }}>
          <Button onClick={redirectToClients} >
            <ListIcon sx={{ marginRight: 3 }}></ListIcon>
          </Button>
        </TableCell>

      </TableRow>


      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0, border: 0 }} colSpan={6} size='small'>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 6, marginBottom: 2 }}>
            <Box sx={{ margin: 2 }}>
              {/* <Box sx={{ display: 'flex' }}>

                <Button
                  sx={{ marginLeft: 'auto' }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => EditAction(row)}
                >  <EditIcon>Edit</EditIcon> {" Edit"}  </Button>
              </Box> */}
              <Table aria-label="subTable">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }} >Hosts</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} >Rules</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details && row.details.map((detailsRow) => (
                    <TableRow key={detailsRow.clients} >
                      <TableCell component="th" scope="row">
                        {detailsRow.clients}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {detailsRow.cmds}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const transformedDataElementAction = (item) => {

  item.clients_key_values = item.clients_key_values || [];
  item.cmds_key_values = item.cmds_key_values || [];


  const clients = item.clients_key_values.map(el => el.name);
  const cmds = item.cmds_key_values.map(el => el.name);

  const length = clients.length > cmds.length ? clients.length : cmds.length;
  const strEmptyCell = "";
  const arrDetails = [];
  for (let i = 0; i < length; i++) {
    let clientCell = clients[i] || strEmptyCell;
    let cmdCell = cmds[i] || strEmptyCell;

    arrDetails.push({ clients: clientCell, cmds: cmdCell });
  }

  const transformedObject = {
    id: item.id,
    key: item.id,
    name: item.name, // item.name,
    is_active: item.is_active ? true : false,
    clients: clients,
    cmds: cmds,
    description: item.description,

    details: arrDetails,

    clients_key_values: item.clients_key_values,
    cmds_key_values: item.cmds_key_values,
  };


  if (item.dynamic && item.dynamic.all_hosts) {
    // allHost === true  // - [ v ]
    transformedObject["all_hosts"] = true;  //  allHost{“all_hosts”: true}, // add the new value
  }


  return transformedObject;
}


const transformedDataArrAction = (groupsData) => {  // groupsData 

  if (!groupsData) { return []; }

  const transformedDataAry = groupsData.map(item => {
    return transformedDataElementAction(item);
  });

  return transformedDataAry;
}

// -----------------------------------------
//   LOCAL STORAGE KEYS
// -----------------------------------------
const LOCAL_KEY_PAGE = 'gruops_page';
const LOCAL_KEY_ROWS_PER_PAGE = 'gruops_rows_per_page';



const CollapsibleTable = () => {

  functionChangeElement.setNewAction(() => {

    const offset = page;
    const limit = rowsPerPage;
    window.location.reload();

  });

  const [totalCount, setTotalCount] = useState(20);

  const [page, setPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_PAGE), 0) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_ROWS_PER_PAGE), 10) || 10);


  const handleChangePage = (event, newPage) => {

    const offset = newPage;
    const limit = rowsPerPage;

    setPage(newPage);

    handleClickedHeader(-1, offset, limit);
  };

  const handleChangeRowsPerPage = (event) => {

    const offset = 0;
    const limit = +event.target.value;

    setRowsPerPage(limit);
    setPage(offset);

    handleClickedHeader(-1, offset, limit);

    // Call with the new fields you want to update

    // Save pagination changes in localStorage
    localStorage.setItem(LOCAL_KEY_PAGE, offset);
    localStorage.setItem(LOCAL_KEY_ROWS_PER_PAGE, limit);

  };

  const [isLoading, setIsLoading] = useState(true);
  const [dataGroups, setDataGroups] = useState([]);


  const apiCallAndDataTransform = (key, orderbyAsc, offset, limit) => {

    setIsLoading(true);
    const totalOffset = offset * limit;

    apiCall(key, orderbyAsc, totalOffset, limit);
  }

  const apiCall = async (key, reverse, totalOffset, limit) => {

    setIsLoading(true);

    try {

      const groupsDataAndCount = await GetGroupsSorting(key, reverse, totalOffset, limit);

      const rulesData = groupsDataAndCount.data;
      const rulesCount = groupsDataAndCount.count;

      const transformedDataAry = transformedDataArrAction(rulesData);

      setTotalCount(rulesCount)
      setDataGroups(transformedDataAry);
    }
    catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }

      // props.logout();
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {

    const offset = page;
    const limit = rowsPerPage;

    const key = "id";
    const orderbyAsc = true;

    apiCallAndDataTransform(key, orderbyAsc, offset, limit);

  }, []);


  const colsHeaderNames = [
    { title: "Active", fieldName: "active" },
    { title: "Group Name", fieldName: "name" },
    { title: "Hosts in Group", fieldName: "clients" },
    { title: "Rules in Groups", fieldName: "cmds" },
    { title: "Description", fieldName: "description" },
    { title: "Edit", fieldName: "ts" },
  ];


  const [colsIndex, setColsIndex] = useState(-1);
  const [reverseSort, setReverseSort] = useState(false);

  const [lastSortKey, setLastSortKey] = useState(colsHeaderNames[0].fieldName);

  const getSortByReverse = (index) => {
    if (index <= -1) {
      return reverseSort;
    }

    let reverse = reverseSort;

    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }

    setReverseSort(reverse);
    return reverse;
  }

  const getKeyToSortBy = (index) => {

    let key = lastSortKey; // "active";

    setColsIndex(index);

    // name lastSeen distribution version
    if (0 <= index && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }

    return key;
  }

  const handleClickedHeader = (index, offset, limit) => {

    setIsLoading(true);

    const reverse = getSortByReverse(index);
    const key = getKeyToSortBy(index);

    apiCall(key, reverse, offset, limit);
  }

  const ModeGroupTableContent = 0;
  const ModeCreate = 1;
  const ModeEdit = 2;
  const [addGroupActiveButton, setAddGroupActiveButton] = useState(ModeGroupTableContent);

  const [idEditObj, setIdEditObj] = useState({ haveId: false, id: 0 });


  if (addGroupActiveButton === ModeCreate) {
    return (
      <CreateGroupData />
    )
  }

  if (addGroupActiveButton === ModeEdit) {
    if (idEditObj.haveId) {

      const editGroupId = idEditObj.id;
      const editGroup = dataGroups.find(el => el.id === editGroupId);
      const clientsKeyValues = editGroup.clients_key_values.map(el => { return { label: el.name, value: el.id } });
      const cmdsKeyValues = editGroup.cmds_key_values.map(el => { return { label: el.name, value: el.id } });
      return (
        <EditGroupData

          groupId={editGroupId}

          groupName={editGroup.name}
          description={editGroup.description}
          is_active={editGroup.is_active}

          initCmds={cmdsKeyValues}
          initClients={clientsKeyValues}

        // allHost={editGroup.dynamic}

          allHosts={editGroup.all_hosts}

        ></EditGroupData>
      )
    }

    return <Box> Not have id ...</Box>
  }


  return (
    <Card>
      <CardContent>
        {/* <Box
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: "20px",
          }}
        >

        </Box> */}

        {isLoading ?
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :

          <>
            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                  <TableRow  >
                    <TableCell sx={{ width: 10, padding: 0 }}>
                      <Box sx={{ width: 10, marginRight: 3 }}>
                        <IconButton variant="outlined" size="small" color='primary' onClick={() => { setAddGroupActiveButton(ModeCreate) }} >
                          <AddIcon></AddIcon>
                        </IconButton>
                      </Box>
                    </TableCell>

                    {colsHeaderNames.map((colItem, index) => (
                      <TableCell
                        // align={colItem.align}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => handleClickedHeader(index, page, rowsPerPage)}
                      >

                        <Box sx={{ display: 'flex' }}>
                          <Box>
                            {colItem.title}
                          </Box>
                          <Box sx={{ justifyItems: 'flex-end' }} >
                            {colsIndex >= 0 && colsIndex === index ?
                              (reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                              : null}
                          </Box>
                        </Box>
                      </TableCell>


                    ))}


                    <TableCell sx={{ padding: 0, margin: 0, paddingRight: 0, fontWeight: 'bold' }} >
                      <Box sx={{ display: "flex", justifyContent: 'flex-start', alignContent: 'center' }}>
                        {/* here is top last col header  */}
                        Execution
                      </Box>
                    </TableCell>


                    <TableCell sx={{ padding: 0, margin: 0, paddingRight: 0, fontWeight: 'bold' }} >
                      <Box sx={{ display: "flex", justifyContent: 'flex-start', alignContent: 'center' }}>
                        {/* here is top last col header  */}
                        Clients
                      </Box>
                    </TableCell>


                  </TableRow>
                </TableHead>
                <TableBody >
                  {dataGroups.map((row) => (
                    <Row key={row.id} row={row} onEditClick={() => { setAddGroupActiveButton(ModeEdit); setIdEditObj({ haveId: true, id: row.id }) }} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        }
      </CardContent>
    </Card>
  );
}

const GroupsTable = () => {
  const pageLoading = CollapsibleTable;
  return pageLoading;
}

export default GroupsTable();
