import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  Box,
  List,
  ListItemText,
  ListItemButton,
  Typography,
  ListItemIcon,
  CssBaseline,
  Toolbar,
  Divider,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Assignment as CompliancyIcon,
  Build as ExecutionIcon,
  LibraryBooks as RulesIcon,
  Group as GroupsIcon,
  Extension as ModulesIcon,
  Notifications as NotificationsIcon,
  Person as UsersIcon,
  SupervisorAccount as RolesIcon,
  Settings as SettingsIcon,
  HelpOutline as FAQIcon,
  ExitToApp as LogoutIcon,
} from "@mui/icons-material";

import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';
import { GetLastestsResults } from './sideBarResuqest';
import Badge from '@mui/material/Badge';
import { useRef } from 'react';
import { ExecutionWithDotBadge } from './ExecutionsWithDotBadge';

const sidebarNavigations = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/compliancy",
    name: "Compliancy",
    icon: <CompliancyIcon />,
  },
  {
    path: "/execution",
    name: "Execution",
    icon: <ExecutionIcon/>,
  },
  {
    path: "/rules",
    name: "Rules",
    icon: <RulesIcon />,
  },
  {
    path: "/groups",
    name: "Groups",
    icon: <GroupsIcon />,
  },
  {
    path: "/users",
    name: "Users",
    icon: <UsersIcon />,
  },
  {
    path: "/roles",
    name: "Roles",
    icon: <RolesIcon />,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon />,
  },
  {
    path: "/legal",
    name: "Legal",
    icon: <FAQIcon />,
  },
  {
    path: "/logout",
    name: "Logout",
    icon: <LogoutIcon />,
  },
];

const drawerWidth = "0vw";


export default function Sidebar({
  onLogOut,
  isLoggedIn,
  isOpen,
  drawerToggle,
}) {
  const nav = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const handleNavigation = (el, path) => {
    if (path === "/logout") {
      onLogOut();
      nav("/login");
    } else {
      nav(path);
      window.location.reload();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        height: "10%",
        width: { xs: isOpen ? drawerWidth : 0 },
        margin: 0, padding: 0
      }}
    >
      <CssBaseline />
      {isLoggedIn && (
        <>
          <Toolbar >
            <Drawer
              open={isOpen}
              onClose={drawerToggle}
              anchor="left"
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { boxSizing: "border-box" },

                backgroundColor: "red",

                margin: 2, paddingTop: 7

              }}


            >
              <Box m={2} pt={7}>
                <List>
                  {sidebarNavigations.map((item, index) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={item.path}
                      onClick={(el) => handleNavigation(el, item.path)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#CCC",
                          "& .MuiListItemIcon-root": {
                            color: "#FFF",
                          },
                        },
                        borderRadius: "1rem",
                        marginBottom: "0.1rem",
                        paddingRight: "2rem",
                        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)',
                        color: "#FFF",
                        background: pathname === item.path ? '#dadee5' : "",
                        color: pathname === item.path ? "#000" : "",
                      }}
                    >
                      <ListItemIcon sx={{
                        color: pathname === item.path ? "#000" : "",
                      }}
                      >{item.icon}</ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItemButton>

                  ))}
                </List>
              </Box>
            </Drawer>
          </Toolbar>
        </>
      )
      }
    </Box >
  );
}
