

import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';
import { GetCommandResultsDashboard } from '../../Shared/HttpRequests';
import Box from '@mui/material/Box';

const size = {
  width: 480,
  height: 340,
};

export default function PieArcLabel(props) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  // State for PieChart data
  const [pieData, setPieData] = useState([]);

  const loadData = async () => {
    try {
      const data = await GetCommandResultsDashboard('name', true, 0, 10);
      setResults(data); // Store response data in state

      // Filter verified and not verified data
      const verifiedData = data.filter((item) => item.verified);
      const notVerifiedData = data.filter((item) => !item.verified);

      // Prepare data for PieChart
      const numA = verifiedData.length;
      const numB = notVerifiedData.length;

      const labelActiveV1 = `compliant (${numA})`; // Verified
      const labelNotActiveV1 = `non compliant (${numB})`; // Not Verified

      const activeHosts = {
        value: numA,
        label: labelActiveV1,
        color: activeBarColor,
      };
      const notActiveHosts = {
        value: numB,
        label: labelNotActiveV1,
        color: notActiveBarColor,
      };

      setPieData([activeHosts, notActiveHosts]); // Update PieChart data

    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    loadData(); // Fetch data on component mount
  }, []);

  return (
    <>
      <Box sx={{ width: 500, height: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'left', marginLeft: 12 }}>
          <h3>Execution</h3>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart
            series={[
              {
                arcLabel: (item) => `(${item.value})`,
                arcLabelMinAngle: 45,
                data: pieData, // Updated PieChart data
                innerRadius: 30,
                outerRadius: 120,
                paddingAngle: 0,
                cornerRadius: 5,
                startAngle: 180,
                endAngle: 360 + 180,
                cx: 150,
                cy: 150,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontWeight: 'bold',
              },
            }}
            {...size}
          />
        </Box>

        {error && <p>Error: {error}</p>}
      </Box>
    </>
  );
}
