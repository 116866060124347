

const leaProdUrl = "https://lea.gsr-it.com";

const localTestingUrl = "https://127.0.0.1:443/";

// const localTestingUrl = "https://127.0.0.1:5000/";
// const localTestingUrl = "http://127.0.0.1:5000/";

//const developmentMode = true;
const developmentMode = false;

let activeUrl = localTestingUrl;

if (!developmentMode) {
    activeUrl = leaProdUrl;
}


const LeaBaseUrl = activeUrl;

export default LeaBaseUrl;
