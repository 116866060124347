import { Box, Button, Container, Input, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { loginSendPost } from "../../Shared/HttpRequests";

import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';

export default function Login({ isLoggedIn, setIsLoggedIn, onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");


  const usernameChangeHandler = (e) => {
    setUsername(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const nav = useNavigate();

  const [isLoading, setIsLoading] = useState(false);


  const handleLogin = () => {
    const loginAction = (username, password) => {

      setIsLoading(true);

      const user = {
        username,
        password,
      };


      loginSendPost(user)
        .then((response) => {
          const token = response.token.trim();
          console.log("Received token");
          console.log(" Token : ", token);

          localStorage.setItem("token", token);
          document.cookie = `session_id=${token}; Path=/; SameSite=None; Secure`;
          setIsLoggedIn(true);
          onLogin();
          nav("/dashboard");
        })
        .catch((err) => {
          console.log(err);

          setErrors("username or password is incorrect");
          setIsLoggedIn(false);
        }).finally(() => {
          setIsLoading(false);
        });

    };

    loginAction(username, password);

  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 8,
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        {isLoading ?

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Input
                type="text"
                placeholder="Username"
                fullWidth
                margin="dense"
                required
                autoFocus
                value={username}
                onChange={usernameChangeHandler}
              />
              <Input
                type="password"
                placeholder="Password"
                fullWidth
                margin="dense"
                required
                value={password}
                onChange={passwordChangeHandler}
              />
              {errors && <Typography color="error">{errors}</Typography>}
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                Sign In
              </Button>
            </Box>


          </>}



      </Box>
    </Container>
  );
}
