import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import { GetCommandResultsDashboard } from '../../Shared/HttpRequests';
import { GetClients } from '../../Shared/HttpRequests';

const defaultColors = [
  "#96FFE8", // Original Soft Teal
  "#FF96AD", // Original Soft Pink
  "#FFC8F0", // Pastel Pink/Peach
  "#C8FFBE", // Soft Mint Green
  "#FFE696", // Soft Yellow/Gold
  "#BEE1FF", // Soft Baby Blue
  "#F0B4FF", // Soft Lavender
  "#FFB4C8", // Pale Coral Blush
  "#FFD5A8", // Soft Apricot
  "#A8FFD8", // Pale Aqua Green
  "#D6A8FF", // Light Lilac
  "#A8D6FF", // Soft Sky Blue
];

function getDistributionVersionCounts(data) {
  const distVerMap = new Map();

  data.forEach((item) => {
    const distField = item.info.dist; // item.info.dist.name.version;
    const key = `${distField.name} ${distField.version}`;
    if (!distVerMap.has(key)) {
      distVerMap.set(key, {
        label: key, // "Distribution Version"
        value: 0,   // Initial count
        color: defaultColors[distVerMap.size % defaultColors.length], // Assign color by index
      });
    }
    // Increment the count
    distVerMap.get(key).value += 1;
  });

  return Array.from(distVerMap.values()); // Convert map to array
}


export default function DistributionVersionPie() {
  const [results, setResults] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedSlices, setSelectedSlices] = useState({});

  const size = {
    width: 680,
    height: 540,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await GetClients(0, 10_000);
        setResults(data);

        const distVerData = getDistributionVersionCounts(data);

        // Initialize all slices as "selected" by default
        const initialSelections = distVerData.reduce((acc, item) => {
          acc[item.label] = true; // true means visible
          return acc;
        }, {});
        setSelectedSlices(initialSelections);

        setPieData(distVerData);
      } catch (err) {
        setError(err.message);
      }
    };

    loadData();
  }, []);

  // Handle toggling the visibility of a slice
  const handleToggle = (label) => {
    setSelectedSlices((prev) => ({
      ...prev,
      [label]: !prev[label], // Toggle the visibility
    }));
  };

  // Filter the pieData based on selected slices
  const filteredPieData = pieData.filter((item) => selectedSlices[item.label]);

  return (
    <Box sx={{ width: 800, padding: 2 }}>
      {/* <Box sx={{ width: 600, padding: 2 }}> */}
      <Box sx={{ display: 'flex', justifyContent: 'left', marginLeft: 2 }}>
        <h3>Distribution</h3>
      </Box>

      {/* <PieChart
  series={[
    {
      arcLabel: (item) => `${item.label}: (${item.value})`,
      data: filteredPieData.map((item) => ({ ...item, fill: item.color })),
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 2,
    },
  ]}
  {...size}
/> */}


      <Box sx={{ display: 'flex', justifyContent: 'center' }}>

        {/* <PieChart
  series={[
    {
      // arcLabel: (item) => `${item.label}: (${item.value})`,
      arcLabel: (item) => `(${item.value})`,
      data: filteredPieData.map((item) => ({ ...item, fill: item.color })),
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 2,
    },
  ]}
  {...size}
/> */}


        <PieChart
          series={[
            {

              // arcLabel: (item) => `${item.label}: (${item.value})`,
              data: filteredPieData.map((item) => ({ ...item, fill: item.color })),

              arcLabel: (item) => `(${item.value})`,
              // data: filteredPieData,
              arcLabelMinAngle: 45,
              innerRadius: 30,
              outerRadius: 120,
              paddingAngle: 0,
              cornerRadius: 5,
              startAngle: 180,
              endAngle: 540,

              cx: 150,
              cy: 150,

              // cx: 150,
              // cy: 150,

            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'black',
              fontWeight: 'bold',
            },
          }}
          {...size}
        />


      </Box>

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6">Toggle Options:</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {pieData.map((item) => (
            <FormControlLabel
              key={item.label}
              control={
                <Checkbox
                  checked={selectedSlices[item.label] || false}
                  onChange={() => handleToggle(item.label)}
                  sx={{ color: item.color }}
                // sx={{ color: item.color , backgroundColor: item.color  }}
                />
              }
              label={`(${item.value}) ${item.label}`}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
