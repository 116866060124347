import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import { useSearchParams } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';
import SearchPopoverServer from '../SearchPopover/SearchPopoverServer';
import ConfirmDialog from './ConfirmDialog';
import { fomattedTimeDisplay, logIfTsPassedNDays } from '../../Shared/UtillsJsFunctions';
import { ColoringValidationSettings } from '../../Shared/ThemesColor';
import { GetSettingsUi, UpdateSettings } from '../../Shared/HttpRequests';
import { GetClientsSorting, GetSearchCompliancyElements, DeleteClientById, GetClientsByIds } from './CompliancyRequests';
import { useQuery } from '@tanstack/react-query';

function Row(props) {
  const { row } = props;
  const nav = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const id = row.id;
  const inactive_status_color = props.inactive_status_color || "gold";
  const optionDeleteClient = props.optionDeleteClient ?? true;
  const resfreshFunc = props.resfreshFunc || (() => { });

  useEffect(() => {
  }, [row]);

  const redirectToExecution = () => {
    nav(`/execution?client_id=${id}`);
  };

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = (response) => {
    setOpenDialog(false);
    if (response === "Yes") {
      DeleteClientById(row.id)
        .then(() => {
          resfreshFunc();
        })
        .catch(error => console.log("[Row] Delete error:", error));
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}>
        <TableCell sx={{ width: 0, padding: 0 }}>
          <Box sx={ColoringValidationSettings(row.verified, inactive_status_color)} />
        </TableCell>
        <TableCell sx={{ padding: 0 }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ paddingLeft: 0 }}>{row.name}</TableCell>
        <TableCell sx={{ paddingLeft: 0 }}>{row.lastSeen}</TableCell>
        <TableCell sx={{ paddingLeft: 0 }}>{row.distribution}</TableCell>
        <TableCell sx={{ paddingLeft: 0 }}>{row.version}</TableCell>
        <TableCell align="center" sx={{ width: 0, padding: 0 }}>
          <Button onClick={redirectToExecution}>
            <ListIcon sx={{ marginRight: 3 }} />
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0, border: 0 }} colSpan={6} size='small'>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 6, marginBottom: 2 }}>
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>LEA Agent Version</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Registered</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>IP Address</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Last Seen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details?.map((detailsRow, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{detailsRow.info_version}</TableCell>
                      <TableCell>{detailsRow.reg_ts}</TableCell>
                      <TableCell>{detailsRow.ip_address}</TableCell>
                      <TableCell>{detailsRow.res_max_ts}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Version</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Release</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>System</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', width: 200 }}>Machine</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details?.map((detailsRow, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{detailsRow.version}</TableCell>
                      <TableCell>{detailsRow.release}</TableCell>
                      <TableCell>{detailsRow.system}</TableCell>
                      <TableCell>{detailsRow.machine}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            {optionDeleteClient && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10 }}>
                <Button onClick={handleClickOpenDialog}>
                  <DeleteIcon sx={{ color: 'black' }} />
                </Button>
                <ConfirmDialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  hostname={row.name}
                  slotProps={{
                    backdrop: { 'aria-hidden': 'false' },
                  }}
                />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function transformClientData(respData, inactiveTime) {
  if (!Array.isArray(respData)) {
    console.warn("[transformClientData] Received non-array data:", respData);
    return [];
  }

  const transformed = respData.map(item => {
    const lastSeenFormat = fomattedTimeDisplay(item.ts);
    const ragTsFormat = fomattedTimeDisplay(item.reg_ts || item.ts);
    const resMaxTsFormat = fomattedTimeDisplay(item.ts);

    item.verified = logIfTsPassedNDays(item.results_max_ts, item.verified, inactiveTime);

    return {
      id: item.id,
      name: item.name,
      compliancy: item.active ? true : false,
      lastSeen: lastSeenFormat,
      distribution: item.info?.dist?.name,
      version: item.info?.dist?.version,
      verified: item.verified,
      details: [
        {
          info_version: item.info?.version,
          reg_ts: ragTsFormat,
          ip_address: item.info?.ip_address,
          res_max_ts: resMaxTsFormat,
          version: item.info?.dist?.version,
          release: item.info?.dist?.release,
          system: item.info?.uname?.system,
          machine: item.info?.uname?.machine,
        },
      ],
    };
  });
  return transformed;
}
const LOCAL_KEY_PAGE = 'compliancy_page';
const LOCAL_KEY_ROWS_PER_PAGE = 'compliancy_rows_per_page';

  const flattenObject = (obj) => {
    const flattened = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
	  if ('dist' in value) { 
		  for (const skey in value['dist']) { 
			  flattened['dist.' + skey] = value.dist[skey];
		  }
	  } 
	  if ('uname' in value) { 
		  for (const skey in value['uname']) { 
			  flattened['uname.' + skey] = value.uname[skey];
		  }
	  } 
	  if ('ip_address' in value) { 
		  flattened['ip_address'] = value.ip_address;
	  }
	  if ('version' in value) { 
		  flattened['lea.version'] = value.version;
	  }

          flattened[key] = JSON.stringify(value).replace(/,/g, " ");
        } else {
          flattened[key] = value;
        }
      }
    }

    return flattened;
  };


const convertToCsv = (jsonData) => {
	if (!jsonData || jsonData.length === 0) {
	return "";
	}

	const flattenedData = jsonData.map((item) => {
		const flattenedItem = flattenObject(item);
		delete flattenedItem.password;
		delete flattenedItem.roles_id;
		return flattenedItem;
	});

	const headers = Object.keys(flattenedData[0]);
	const rows = flattenedData.map((row) =>
	headers.map((header) => `"${row[header] || ""}"`).join(",")
	);
	return [headers.join(","), ...rows].join("\n");
};

const handleDownloadCsv = () => {
        const data = GetClientsSorting('name', false, 0, 100000).then(resp =>{ 
		const csvData = convertToCsv(resp.data);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "data.csv";
		a.click();
		URL.revokeObjectURL(url);
	});
};



export default function CollapsibleTable(props) {
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_PAGE), 0) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_ROWS_PER_PAGE), 10) || 10);
  const [inactiveStatusColor, setInactiveStatusColor] = useState("red");
  const [inactiveTime, setInactiveTime] = useState(14);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [clientHosts, setClientHosts] = useState([]);
  const [colsIndex, setColsIndex] = useState(-1);
  const [reverseSort, setReverseSort] = useState(false);
  const [lastSortKey, setLastSortKey] = useState("ts");
  const [localLoading, setLocalLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const queryParamsRef = useRef({});
  for (const [key, value] of searchParams.entries()) {
    queryParamsRef.current[key] = value;
  }

  const colsHeaderNames = [
    { title: "Host Name", fieldName: "name" },
    { title: "Last Seen", fieldName: "ts" },
    { title: "Distribution", fieldName: "info->dist->>name" },
    { title: "Version", fieldName: "info->>version" },
  ];

  useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();
        const settings_ui_Obj = settings_ui_Obj_data_list[0]?.settings || {};
        setInactiveTime(settings_ui_Obj.client_inactive_time ?? 14);
        setInactiveStatusColor(settings_ui_Obj.client_inactive_status_color ?? "red");
        setSettingsLoaded(true);
      } catch (error) {
        console.error("[CollapsibleTable] Error fetching settings data:", error);
      }
    };
    initializeSettings();
  }, []);

  async function fetchCompliancyData() {
    const offsetNum = page * rowsPerPage;
    let resp = [];
    let haveQueryParamClients = false;
  const handleSyncData = async () => {
    refetch({ exact: true });
  };

  const filteringByValue = (value, key, id) => {
    queryParamsRef.current.client_id = id;
    delete queryParamsRef.current.clientsIds;
  };

    if (haveQueryParamClients) {
      const clientsIds = queryParamsRef.current.clientIds.split(',');
      resp = await GetClientsByIds(clientsIds, lastSortKey, reverseSort, offsetNum, rowsPerPage);
      delete queryParamsRef.current.clientsIds;
    }
    else {
      resp = await GetClientsSorting(lastSortKey, reverseSort, offsetNum, rowsPerPage);
    }
    return resp;
  }

  const {
    data: queryResult,
    isLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: ['compliancyData', lastSortKey, reverseSort, page, rowsPerPage],
    queryFn: fetchCompliancyData,
    enabled: settingsLoaded,
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    staleTime: 0,
    cacheTime: 0
  });

  useEffect(() => {
    if (queryResult?.data) {
      const { data: rawRows, count } = queryResult;
      setTotalCount(count || 0);

      const transformed = transformClientData(rawRows, inactiveTime);
      setClientHosts(transformed);
    } else if (!isLoading && !isError && queryResult) {
      console.warn("[useEffect] queryResult has no data field:", queryResult);
    }
  }, [queryResult, inactiveTime, isLoading, isError]);

  const handleSyncData = () => {
    refetch();
  };

  const handleClickedHeader = (index) => {
    let reverse = reverseSort;
    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }
    setReverseSort(reverse);
    setColsIndex(index);

    let key = lastSortKey;
    if (index >= 0 && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    const limit = +event.target.value;
    const offset = 0;
    setRowsPerPage(limit);
    setPage(0);
    localStorage.setItem(LOCAL_KEY_PAGE, offset);
    localStorage.setItem(LOCAL_KEY_ROWS_PER_PAGE, limit);

  };

  const resfreshFunc = () => {
    refetch();
  };

  const loadingOrError = isLoading || isError || localLoading;


  return (
    <Card>
      <CardContent>
        {loadingOrError ? (
          isError ? (
            <p style={{ color: 'red' }}>Error fetching data</p>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <GradientCircularProgress />
            </Box>
          )
        ) : (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: 1 }} />
                    <TableCell sx={{ paddingLeft: 2 }} />
                    {colsHeaderNames.map((colItem, index) => (
                      <TableCell
                        key={colItem.fieldName}
                        sx={{ fontWeight: 'bold', cursor: 'pointer', padding: 0 }}
                        onClick={() => handleClickedHeader(index)}
                      >
                        <Box sx={{ width: 200 }}>
                          <Box>{colItem.title}</Box>
                          <Box>
                            {colsIndex >= 0 && colsIndex === index && (
                              reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    ))}


                    <TableCell sx={{ padding: 0, marginRight: 0, paddingRight: 4 }}>
		              <Button sx={{  borderRadius: "1rem" }} size="small" variant="outlined" onClick={handleDownloadCsv}>
				CSV
			      </Button>

		    </TableCell>
                    <TableCell sx={{ padding: 0, margin: 0, paddingRight: 0 }}>
                      <Box sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <SearchPopoverServer
                          filteringByValue={(value, key, id) => {
                            setLocalLoading(true);
                            setClientHosts([]);
                            setPage(0);
                            setColsIndex(-1);

                            GetSearchCompliancyElements(id)
                              .then(response => {
                                const transformed = transformClientData(response, inactiveTime);
                                setClientHosts(transformed);
                              })
                              .catch(error => console.log("[CollapsibleTable] filteringByValue error:", error))
                              .finally(() => setLocalLoading(false));
                          }}
                          handleSyncData={handleSyncData}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientHosts.map((row) => (
                    <Row
                      key={row.id}
                      row={row}
                      inactive_status_color={inactiveStatusColor}
                      resfreshFunc={resfreshFunc}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

