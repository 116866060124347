import React from 'react';
import { mySettings } from '../../Shared/MySettings';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import { isPositiveWholeNumber } from "../../Shared/UtillsJsFunctions";
import { GetSettingsUi } from '../../Shared/HttpRequests';
import { UpdateSettings } from "./SettingsRequests";
import { GetRules } from './SettingsRequests';
import { notActiveBarColor } from '../../Shared/ThemesColor';

const colorsRed = notActiveBarColor;
const colorsYellow = "#e8dfaf";
const colorsGray = "#CCC";

function BasicCard() {
  const defualtColor = colorsRed;
  const [clientInactiveStatusColor, setInactiveClientStatusColor] = React.useState(defualtColor);
  const [clientInactiveTime, setClientInactiveTime] = useState(14);
  const [executionInactiveStatusColor, setInactiveExecutionStatusColor] = React.useState(defualtColor);
  const [executionInactiveTime, setExecutionInactiveTime] = useState(14);
  const [stdoutShortenNumber, setStdoutShortenNumber] = useState(12);
  const [stderrShortenNumber, setStderrShortenNumber] = useState(12);
  const [stdoutSubShortenNumber, setStdoutSubShortenNumber] = useState(12);
  const [stderrSubShortenNumber, setStderrSubShortenNumber] = useState(12);
  const [numberRowsInExecution, setNumberRowsInExecution] = useState(200);
  const [settingsLoaded, setSettingsLoaded] = useState(false); // New state to track settings loading
  const [textToSend, setTextToSend] = React.useState("");
  const [allRules, setAllRules] = useState([]);  // To store command names
  const [selectedCmd, setSelectedCmd] = useState('');  // Selected value
  const [ruleName, setRuleName] = useState('');  // Selected value
  const dataSettingUiRef = React.useRef({});
  React.useEffect(() => {
    const initializeSettings = async () => {
      try {
        const settings_ui_Obj_data_list = await GetSettingsUi();
        const settings_ui_Obj = settings_ui_Obj_data_list[0].settings;
        dataSettingUiRef.current = settings_ui_Obj;
        setInactiveClientStatusColor(settings_ui_Obj.client_inactive_status_color || clientInactiveStatusColor);
        setClientInactiveTime(settings_ui_Obj.client_inactive_time || clientInactiveTime);
        setInactiveExecutionStatusColor(settings_ui_Obj.execution_inactive_status_color || executionInactiveStatusColor);
        setExecutionInactiveTime(settings_ui_Obj.execution_inactive_time || executionInactiveTime);
        setStdoutShortenNumber(settings_ui_Obj.stdout_shorten_number || stdoutShortenNumber);
        setStderrShortenNumber(settings_ui_Obj.stderr_shorten_number || stderrShortenNumber);
        setStdoutSubShortenNumber(settings_ui_Obj.stdout_sub_shorten_number || stdoutSubShortenNumber);
        setStderrSubShortenNumber(settings_ui_Obj.stderr_sub_shorten_number || stderrSubShortenNumber);
        setNumberRowsInExecution(settings_ui_Obj.number_rows_execution || numberRowsInExecution);
        setRuleName(settings_ui_Obj.picked_rule_name || picked_rule_name );
        const allRules = await GetRules();
        setAllRules(allRules);
        setSettingsLoaded(true); // Mark settings as loaded
      } catch (error) {
        console.error("Error fetching settings data:", error);
      }
    };
    initializeSettings();
  }, []); // Runs only once on component mount
  const handleClientInactiveStatusColor = (event) => {
    setInactiveClientStatusColor(event.target.value);
  };
  const handleClientInactiveTime = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setClientInactiveTime(inputTxt);
      // const checkIfEmpty = inputTxt.trim() === "";
    }
  };
  const handleExecutionInactiveStatusColor = (event) => {
    setInactiveExecutionStatusColor(event.target.value);
  };
  const handleExecutionInactiveTime = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setExecutionInactiveTime(inputTxt);
    }
  };
  const handleNumberRowsInExecution = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setNumberRowsInExecution(inputTxt);
    }
  }
  const handleStdoutShortenNumber = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setStdoutShortenNumber(inputTxt);
    }
  }
  const handleStderrShortenNumber = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setStderrShortenNumber(inputTxt);
    }
  }
  const handleStdoutSubShortenNumber = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setStdoutSubShortenNumber(inputTxt);
    }
  }
  const handleStderrSubShortenNumber = (event) => {
    const inputTxt = event.target.value;
    if (isPositiveWholeNumber(inputTxt)) {
      setStderrSubShortenNumber(inputTxt);
    }
  }
  const handleRuleName = (event) => {
    setRuleName(event.target.value);
  };
  const handleSaveSettings = async () => {
    const settingsObj =  {...dataSettingUiRef.current}; 
      settingsObj.client_inactive_status_color = clientInactiveStatusColor;
      settingsObj.client_inactive_time = clientInactiveTime;
      settingsObj.execution_inactive_status_color = executionInactiveStatusColor;
      settingsObj.execution_inactive_time = executionInactiveTime;
      settingsObj.stdout_shorten_number = stdoutShortenNumber;
      settingsObj.stderr_shorten_number = stderrShortenNumber;
      settingsObj.stdout_sub_shorten_number = stdoutSubShortenNumber;
      settingsObj.stderr_sub_shorten_number = stderrSubShortenNumber;
      settingsObj.number_rows_execution = numberRowsInExecution;
      settingsObj.picked_rule_name = ruleName;
    const settingObjString = JSON.stringify(settingsObj, null, 3);
    setTextToSend(settingObjString);
    try {
      const updatedAnswer = await UpdateSettings(settingsObj);
    }
    catch (error) {
      console.error("Error updated settings : ", error);
    }
  }

  const sizeLabelSpace = 4;
  const sizeFieldSpace = 12 - sizeLabelSpace;
  const sizeFonts = "small";
    const handleChange = (event) => {
      setSelectedCmd(event.target.value);
    };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box sx={{ margin: 6 }}>
          <Box sx={{ width: 700 }}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }} >
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button onClick={handleSaveSettings} ><SaveIcon /> </Button>
                </Box>
              </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Client Inactive Status Color  </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box>
                  <Box sx={{ width: 196 }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Color</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={clientInactiveStatusColor}
                        label="Color"
                        onChange={handleClientInactiveStatusColor}
                        size="small"
                        sx={{ fontSize: sizeFonts, height: 40 }}
                      >
                        <MenuItem value={colorsRed} > Red </MenuItem>
                        <MenuItem value={colorsGray}>Gray</MenuItem>
                        <MenuItem value={colorsYellow}>Yellow</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Client Inactive Time ( Days )  </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleClientInactiveTime}
                    value={clientInactiveTime}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Execute Inactive Status Color  </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box>
                  <Box sx={{ width: 196 }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Color</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={executionInactiveStatusColor}
                        label="Color"
                        onChange={handleExecutionInactiveStatusColor}
                        size="small"
                        sx={{ fontSize: sizeFonts, height: 40 }}
                      >
                        <MenuItem value={colorsRed}  >Red</MenuItem>
                        <MenuItem value={colorsGray} >Gray</MenuItem>
                        <MenuItem value={colorsYellow} >Yellow</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Execute Inactive Time ( Days )  </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Days" variant="outlined"
                    placeholder="Name"
                    onChange={handleExecutionInactiveTime}
                    value={executionInactiveTime}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Stdout Top </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Characters" variant="outlined"
                    placeholder="Name"
                    onChange={handleStdoutShortenNumber}
                    value={stdoutShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Stderr Top </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Characters" variant="outlined"
                    placeholder="Name"
                    onChange={handleStderrShortenNumber}
                    value={stderrShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Stdout Sub </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Characters" variant="outlined"
                    placeholder="Name"
                    onChange={handleStdoutSubShortenNumber}
                    value={stdoutSubShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Character Stderr Sub </p>
                </Box>
              </Grid>
              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Characters" variant="outlined"
                    placeholder="Name"
                    onChange={handleStderrSubShortenNumber}
                    value={stderrSubShortenNumber}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Number Of Rows In Execution  </p>
                </Box>
              </Grid>

              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Number Of Rows" variant="outlined"
                    placeholder="Name"
                    onChange={handleNumberRowsInExecution}
                    value={numberRowsInExecution}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>

              <Grid item xs={sizeLabelSpace} >
                <Box>
                  <p style={{ fontSize: sizeFonts }} > Pick Machine To Show </p>
                </Box>
              </Grid>

              <Grid item xs={sizeFieldSpace} >
                <Box sx={{ width: 200 }}>
                  <TextField id="outlined-basic" label="Rule name" variant="outlined"
                    placeholder="Name"
                    onChange={handleRuleName}
                    value={ruleName}
                    size="small"
                    sx={{ fontSize: sizeFonts }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function Settings() {
  return (<>
    <BasicCard></BasicCard>
  </>
  )
}
