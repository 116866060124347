
import LeaBaseUrl from '../../Shared/LeaBaseUrl';
import { GetHeadersContentAuth } from '../../Shared/AuthShared';
import { AuthAndCountHeaders, GetDataAndCountResponse } from '../../Shared/UtillsJsFunctions';
import axios from 'axios';



const GetClientsSorting = async (sortField, orderAsc = true, offset = 0, limit = 100) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    const url = `${LeaBaseUrl}/api/clients_verified_status?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}





const GetClientsByIds = async (ids ,sortField, orderAsc = true, offset = 0, limit = 100) => {

    const sortOrder = orderAsc ? "asc" : "desc";
    // https://127.0.0.1//api/clients?id=in.(1,2,3,4)
    // const url = `${LeaBaseUrl}/api/clients_verified_status?order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;
    const url = `${LeaBaseUrl}/api/clients?id=in.(${ids})&order=${sortField}.${sortOrder}&offset=${offset}&limit=${limit}`;

    const config = {
        headers: AuthAndCountHeaders()
    };

    try {
        const resp = await axios.get(url, config);
        const resultDataAndCount = GetDataAndCountResponse(resp);
        return resultDataAndCount;
    } catch (err) {
        return Promise.reject(err);
    }
}


const GetSearchCompliancyElements = (searchParam, sortField = "name") => {

    const sortOrder = "asc";
    const url = `${LeaBaseUrl}/api/clients_verified?id=eq.${searchParam}&order=${sortField}.${sortOrder}`;

    const config = {
        headers: GetHeadersContentAuth()
    };

    return axios
        .get(url, config)
        .then(resp => {
            const clientsDataObj = resp.data;
            return clientsDataObj;
        })
        .catch(
            err => {
                return Promise.reject(err);
            }
        )
}


const DeleteClientById = async (id) => {

    const url = `${LeaBaseUrl}/api/clients?id=eq.${id}`;

    const config = {
        headers: GetHeadersContentAuth()
    };

    try {
        const resp = await axios.delete(url, config);
        const clientsDataObj = resp.data;
        return clientsDataObj;
    } catch (err) {
        return Promise.reject(err);
    }
}



export { GetClientsByIds };
export { GetClientsSorting };
export { GetSearchCompliancyElements };
export { DeleteClientById };

