import axios from 'axios'
import LeaBaseUrl from './LeaBaseUrl'
import { GetHeadersContentAuth } from '../Shared/AuthShared';


const loginSendPost = (user) => {

    const url = LeaBaseUrl + "/lea/authenticate_api"

    const resOfUsrPlusPass = btoa(unescape(encodeURIComponent(user.username + ':' + user.password)))
    const dataObj = []


    // console.log(resOfUsrPlusPass)
    // console.log("url login : " , url );


    return axios
        .post(url,
            dataObj,
            {
                headers:
                {
                    Authorization: `Basic ${resOfUsrPlusPass}`
                }
            }, { withCredentials: true })
        .then((response) => {
            const respData = response.data
            console.log(respData)
            return respData
        })
        .catch((err) => {
            console.log("catch of the login js page")
            return Promise.reject(err)
        })
}


const GetClients = (offset = 0, limit = 100) => {

    const url = `${LeaBaseUrl}/api/clients_verified_status?order=id.asc&offset=${offset}&limit=${limit}`;

    const config = {
        headers: GetHeadersContentAuth()
    };

    return axios
        .get(url, config)
        .then(resp => {
            const clientsDataObj = resp.data;
            return clientsDataObj;
        })
        .catch(
            err => {
                // console.log(" Error : HTTP REQ PAGE : ", err.toJSON());
                // console.log(" Error : HTTP REQ PAGE >> err.response", err.response)
                return Promise.reject(err);
                // return err
                // return Promise.reject(err);
            }
        )
}


const fetchData = async (endpoint) => {
    const url = `${LeaBaseUrl}/api/${endpoint}?order=name.asc`;
    const config = {
        headers: GetHeadersContentAuth(),
    };

    try {
        const resp = await axios.get(url, config);
        return resp.data;
    } catch (err) {
        return Promise.reject(err);
    }
};

const GetAllClients = async () => {
    return fetchData('clients_verified');
};

const GetAllCmds = async () => {
    return fetchData('cmds');
};


const GetSearchHosts = async (filter = "name") => {

    const sortOrder = "asc";

    const url = `${LeaBaseUrl}/api/clients_verified?order=${filter}.${sortOrder}`;

    const config = {
        headers: GetHeadersContentAuth()
    };

    try {
        const resp = await axios.get(url, config);
        return resp.data;
    } catch (err) {
        return Promise.reject(err);
    }
}




const GetSettingsUi = () => {

    const url = `${LeaBaseUrl}/api/settings_ui?name=eq.settings_front`;


    const config = {
        headers: GetHeadersContentAuth()
    };

    return axios
        .get(url, config)
        .then(resp => {
            const settings_ui_Obj = resp.data;
            return settings_ui_Obj;
        })
        .catch(
            err => {
                return Promise.reject(err);
            }
        )
}

const UpdateSettings = (data) => {
    const url = `${LeaBaseUrl}/api/settings_ui?name=eq.settings_front`;

    const config = {
        headers: {
            ...GetHeadersContentAuth(),
            'Prefer': 'return=representation' // Ensure the updated values are returned
        }
    };

    console.log("Partial update payload:", data);

    // Use PATCH to send partial updates
    return axios
        .patch(url, { settings: data }, config)
        .then((resp) => {
            console.log("Updated settings response:", resp.data);
            return resp.data;
        })
        .catch((err) => {
            console.error("Error during partial update:", err);
            return Promise.reject(err);
        });
};




const GetCommandResultsDashboard = async (sortField) => {
    
    const url = `${LeaBaseUrl}/api/latest_cmd_results_all`;
    
    const config = {
        headers: { ...GetHeadersContentAuth() }
    };
    
    try {
        const resp = await axios.get(url, config);
        return resp.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

const loginSendPostSSO = (token) => {
    const url = LeaBaseUrl + "/lea/authenticate_api_from_token"
    const dataObj = []
    return axios
        .post(url,
            dataObj,
            {
                headers:
                {
                   Authorization: `Bearer ${token}`
                }
            })
        .then((response) => {
            const respData = response.data
            console.log(respData)
            return respData
        })
        .catch((err) => {
            console.log("catch of the login js page")
            return Promise.reject(err)
        })
}

export { loginSendPostSSO };


export { GetCommandResultsDashboard };

export { UpdateSettings };
export { GetSettingsUi };
export { loginSendPost };
export { GetAllCmds };
export { GetAllClients };
export { GetSearchHosts };
export { GetClients };
