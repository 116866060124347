import React, { useEffect, useState } from 'react';
import { GetClients } from '../../Shared/HttpRequests';
import PieChartCompliancy from './PieChartCompliancy';
import PieChartExecution from './PieChartExecution';
import PieChartByRule from './PieChartByRule';
import DistributionVersionPie from './PieChartDistribution.jsx';
import BasicBars from './BarChart';
import { functionChangeElement } from '../../Shared/FunctionChange';
import { GetSettingsUi, UpdateSettings } from '../../Shared/HttpRequests';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

export default function Dashboard({ isLoggedIn }) {
    functionChangeElement.setNewAction(() => {
        console.log("%c Dashboard ! ", "background: white ; color: black;");
        window.location.reload();
    });
    const [totalClientHosts, setTotalCleintHosts] = useState(0);
    const [totalVerifiedClients, setTotalVerifiedClients] = useState(0);
    const [totalUnVerifiedClients, setTotalUnVerifiedClients] = useState(0);
    const [dataClients, setDataClients] = useState({});
    const [picked_cmd_name, set_picked_cmd_name] = useState("Host Name");
    const apiCallAndDataTransform = () => {
        const limit = 1000;
        const offset = 0;
        GetClients(offset, limit).then(response => {
            const numberOfTotalClientHosts = response.length;
            let verifiedCounts = 0;
            response.forEach(client => {
                if (client.verified === true) {
                    verifiedCounts++;
                }
            })
            setTotalCleintHosts(numberOfTotalClientHosts);
            setTotalVerifiedClients(verifiedCounts);
            setTotalUnVerifiedClients(numberOfTotalClientHosts - verifiedCounts);
            setDataClients(response)
        })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }
    useEffect(() => {
        apiCallAndDataTransform();
    }, [])
     useEffect(() => {
        const initializeSettings = async () => {
          try {
            const settings_ui_Obj_data_list = await GetSettingsUi();
            const settings_ui_Obj = settings_ui_Obj_data_list[0]?.settings || {};
            set_picked_cmd_name( settings_ui_Obj.picked_rule_name|| picked_cmd_name)
          } catch (error) {
            console.error("[CollapsibleTable] error fetching UI settings:", error);
          }
        };
        initializeSettings();
      }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={70} columnSpacing={{ xs: 1, sm: 12, md: 3 }} >
        {/*
            <Grid item xs={4} md={5}>
                 <PieChartCompliancy totalVerifiedClients={totalVerifiedClients} totalUnVerifiedClients={totalUnVerifiedClients}   ></PieChartCompliancy>
            </Grid>
            <Grid item xs={6} md={6}>
              <BasicBars dataClients={dataClients}  ></BasicBars>
            </Grid>
            <Grid item xs={6} md={6}>
                 <PieChartExecution totalVerifiedClients={totalVerifiedClients} totalUnVerifiedClients={totalUnVerifiedClients}   ></PieChartExecution>
            </Grid>
            <Grid item xs={6} md={6}>
                 <PieChartByRule totalVerifiedClients={totalVerifiedClients} totalUnVerifiedClients={totalUnVerifiedClients} picked_cmd_name={picked_cmd_name}   ></PieChartByRule>
            </Grid>
            <Grid item xs={6} md={6}>
                 <DistributionVersionPie />
            </Grid>
            <Grid item xs={4} md={5}>
            </Grid>
            */}

            <Grid item xs={12} md={12}>
            <iframe src="/kibana/app/dashboards#/view/49bd182d-5e75-41b0-9c77-aaa45809950c?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-1h%2Cto%3Anow))&show-top-menu=true&show-query-input=true&show-time-filter=true" height="1200" width="100%"></iframe>
            </Grid>
          </Grid>
        </Box>
      );
    }
