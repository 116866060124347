import React from 'react'

import { useNavigate } from "react-router-dom";

import { useEffect, useState } from 'react';



import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import EditIcon from '@mui/icons-material/Edit';


import CreateRuleComponent from './CreateRuleComponent';
import EditRuleComponent from './EditRuleComponent';


import { ColoringValidation, activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';

import TablePagination from '@mui/material/TablePagination';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';

import { GetRulesSorting } from './RulesRequests.js';


import { functionChangeElement } from '../../Shared/FunctionChange';


import ListIcon from '@mui/icons-material/List';

import CheckIcon from '@mui/icons-material/Check';


import { GetSettingsUi } from '../../Shared/HttpRequests.js';


import { UpdateSettings } from '../../Shared/HttpRequests.js';


const ColorIntectionVerified = (verified) => {
  if (verified === true) { return activeBarColor; }
  if (verified === false) { return notActiveBarColor; }

  return '#CCC';
};


function Row(props) {
  const { row } = props;

  const EditAction = (row) => {
    if (props.onEditClick) {
      props.onEditClick();
    }
  }

  const nav = useNavigate();

  const cmd_id = row.id;

  const redirectToExecution = () => {

    const restRedirectUrl = `/execution?cmd_id=${cmd_id}`

    console.log("Redirect");
    nav(restRedirectUrl);

  };

  const barColor = ColorIntectionVerified(row.verified);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}   >

        <TableCell align="" sx={{ width: 0, padding: 0 }}>
          <Box sx={{ ...ColoringValidation(row.isActive), backgroundColor: barColor }} ></Box>
        </TableCell>

        {/* <TableCell component="th" scope="row" sx={{ padding: 0, margin: 0, width: 20 }} align="left" >
          <Box sx={{ marginLeft: 2 }} >
            {row.isActive ? <CheckIcon></CheckIcon> : null}
          </Box>
        </TableCell> */}

        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0, }}  >
          {row.name}
        </TableCell>

        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.description} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.category} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.module} </TableCell>
        {/* number.toLocaleString(); */}
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.interval.toLocaleString()} </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >
          <Button sx={{ width: 10, height: 5 }} startIcon={<EditIcon></EditIcon>} onClick={() => EditAction(row)}>

          </Button>
        </TableCell>

        <TableCell align="center" sx={{ width: 0, padding: 0 }}>
          <Button onClick={redirectToExecution} >
            <ListIcon sx={{ marginRight: 3 }}></ListIcon>
          </Button>
        </TableCell>

      </TableRow>

    </React.Fragment >
  );
}

// -----------------------------------------
//   LOCAL STORAGE KEYS
// -----------------------------------------
const LOCAL_KEY_PAGE = 'rules_page';
const LOCAL_KEY_ROWS_PER_PAGE = 'rules_rows_per_page';



const transformedDataElementAction = (item) => {

  const number = item.cycle;
  const formattedNumber = number;

  const transformedObject = {
    id: item.id,
    key: item.id,

    isActive: item.active ? true : false,
    name: item.name, // item.name,
    description: item.description,

    module: item.module || "",

    category: item.category,
    interval: formattedNumber,

    rule: item.cmd,
    ruleTemplate: item.cmd_templates,
    verified: item.verified,


  };

  return transformedObject;
}

const transformedDataArrAction = (rulesData) => {

  if (!rulesData) {
    return [];
  }

  const transformedDataAry = rulesData.map(item => {
    return transformedDataElementAction(item);
  });

  return transformedDataAry;
}

const Rules = (props) => {

  functionChangeElement.setNewAction(() => {

    const offset = page;
    const limit = rowsPerPage;

    console.log("%c Rules! ", "background: purple ;");
    // handleClickedHeader(-1, offset, limit);

    window.location.reload();

  });

  const [totalCount, setTotalCount] = useState(20);


  const [page, setPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_PAGE), 0) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_ROWS_PER_PAGE), 10) || 10);

  const handleChangePage = async (event, newPage) => {

    const offset = newPage;
    const limit = rowsPerPage;

    setPage(offset);

    handleClickedHeader(-1, offset, limit);

  };

  const handleChangeRowsPerPage = async (event) => {

    const limit = +event.target.value;
    const offset = 0;

    setRowsPerPage(limit);
    setPage(offset);

    handleClickedHeader(-1, offset, limit);


    // Call with the new fields you want to update

    // Save pagination changes in localStorage
    localStorage.setItem(LOCAL_KEY_PAGE, offset);
    localStorage.setItem(LOCAL_KEY_ROWS_PER_PAGE, limit);

  };

  const [dataRules, setDataRules] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const apiCall = async (key, reverse, totalOffset, limit) => {

    setIsLoading(true);

    try {

      const rulesDataAndCount = await GetRulesSorting(key, reverse, totalOffset, limit);

      const rulesData = rulesDataAndCount.data;
      const rulesCount = rulesDataAndCount.count;

      const transformedDataAry = transformedDataArrAction(rulesData);

      setTotalCount(rulesCount)
      setDataRules(transformedDataAry);
    }
    catch (error) {
      if (error.response && error.response.status < 400) { return; }

      props.logout();
    } finally {
      setIsLoading(false);
    }
  }


  const colsHeaderNames = [
    // { title: "Active", fieldName: "active" },
    { title: "Rule Name", fieldName: "name" },
    { title: "Description", fieldName: "description" },
    { title: "Category", fieldName: "category" },
    { title: "Module", fieldName: "" },
    { title: "Interval (Seconds)", fieldName: "cycle" },
    // { title: "Edit", fieldName: "" },
    // { title: "", fieldName: "" },
  ];


  useEffect(() => {

    const offset = page;
    const limit = rowsPerPage;

    // apiCallAndDataTransform(offset, limit);

    handleClickedHeader(-1, offset, limit);
  }, []);


  const [colsIndex, setColsIndex] = useState(-1);
  // const [reverseSort, setReverseSort] = useState(false);
  const [reverseSort, setReverseSort] = useState(true);


  const [lastSortKey, setLastSortKey] = useState(colsHeaderNames[0].fieldName);

  const checkReverse = (index) => {
    let reverse = reverseSort;

    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }

    setReverseSort(reverse);
    return reverse;
  }

  const handleClickedHeader = (index, offset, limit) => {

    const reverse = index > -1 ? checkReverse(index) : reverseSort;
    let key = lastSortKey; // "active";

    setColsIndex(index);

    // name lastSeen distribution version
    if (0 <= index && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }

    const totalOffset = offset * limit;
    apiCall(key, reverse, totalOffset, limit);
  }



  const ModeRulesTableContent = 0;
  const ModeCreate = 1;
  const ModeEdit = 2;
  const [addRuleActiveButton, setAddRuleActiveButton] = useState(ModeRulesTableContent);


  const [idEditObj, setIdEditObj] = useState({ haveId: false, id: 0 });


  if (addRuleActiveButton === ModeCreate) {
    return (
      <CreateRuleComponent></CreateRuleComponent>
    )
  }


  if (addRuleActiveButton === ModeEdit) {
    if (idEditObj.haveId) {
      const editRuleId = idEditObj.id;
      const editRule = dataRules.find(el => el.id === editRuleId);

      return (
        <EditRuleComponent

          ruleName={editRule.name}
          description={editRule.description}
          isActive={editRule.isActive}
          rule={editRule.rule}
          category={editRule.category}
          interval={editRule.interval}
          ruleTemplate={editRule.ruleTemplate}
          ruleId={editRule.id}


        ></EditRuleComponent>
      )
    }

    return <Box> Not have id ...</Box>
  }


  return (
    <Card>
      <CardContent>

        {isLoading ?

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>

            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                  <TableRow >

                    <TableCell sx={{ width: 10, padding: 0 }}>
                      <Box sx={{ width: 10, marginRight: 3 }}>
                        <IconButton variant="outlined" size="small" color='primary' onClick={() => { setAddRuleActiveButton(ModeCreate) }} >
                          <AddIcon></AddIcon>
                        </IconButton>
                      </Box>
                    </TableCell>

                    {colsHeaderNames.map((colItem, index) => (

                      <TableCell
                        // align={colItem.align}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => handleClickedHeader(index, page, rowsPerPage)}
                      >

                        <Box sx={{ display: 'flex' }}>
                          <Box>
                            {colItem.title}
                          </Box>
                          <Box sx={{ justifyItems: 'flex-end' }} >

                            {
                              colsIndex >= 0 && colsIndex === index ?
                                (reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                                : null
                            }

                          </Box>
                        </Box>
                      </TableCell>

                    ))}

                    <TableCell sx={{ fontWeight: 'bold' }} >

                      <Box >
                        Edit
                      </Box>
                    </TableCell>


                    <TableCell sx={{ padding: 0, margin: 0, paddingRight: 0, fontWeight: 'bold' }} >
                      <Box sx={{ display: "flex", justifyContent: 'flex-start', alignContent: 'center' }}>
                        {/* here is top last col header  */}
                      </Box>
                    </TableCell>


                  </TableRow>
                </TableHead>
                <TableBody >
                  {dataRules.map((row) => (
                    <Row key={row.id} row={row} onEditClick={() => { setAddRuleActiveButton(ModeEdit); setIdEditObj({ haveId: true, id: row.id }) }} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>


            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={dataRules.length}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </>}

      </CardContent>
    </Card>
  );
}


export default Rules;

// export default function Rules() {
//   return (
//     <div>Rules</div>
//   )
// }
