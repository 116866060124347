import React from 'react'
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card, CardContent } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GradientCircularProgress } from '../LoadingSpinner/LoadingSpinnerComponent';
import { GetRolesSorting } from './RolesRequests';
import { functionChangeElement } from '../../Shared/FunctionChange';
import { GetSettingsUi } from '../../Shared/HttpRequests';
import { UpdateSettings } from '../../Shared/HttpRequests.js';

function Row(props) {
  const { row } = props;
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, padding: 0, margin: 0 }}   >
        <TableCell sx={{ padding: 0 }}>
          <Box sx={{ width: 32 }} > </Box>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }}  >
          <Box >
            {row.name}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 2, margin: 0 }} >{row.description} </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const transformedDataElementAction = (item) => {
  const transformedObject = {
    id: item.id,
    key: item.id,
    name: item.name,
    description: item.description,
  };
  return transformedObject;
}

const transformedDataArrAction = (usersData) => {
  if (!usersData) { return []; }
  const transformedDataAry = usersData.map(item => {
    return transformedDataElementAction(item);
  });
  return transformedDataAry;
}

const LOCAL_KEY_PAGE = 'roles_page';
const LOCAL_KEY_ROWS_PER_PAGE = 'roles_rows_per_page';
const Roles = (props) => {
  functionChangeElement.setNewAction(() => {
    const offset = page;
    const limit = rowsPerPage;
    window.location.reload();
  });
  const [totalCount, setTotalCount] = useState(20);
  const [page, setPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_PAGE), 0) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(localStorage.getItem(LOCAL_KEY_ROWS_PER_PAGE), 10) || 10);
  const handleChangePage = (event, newPage) => {
    const offset = newPage;
    const limit = rowsPerPage;
    setPage(offset);
    handleClickedHeader(-1, offset, limit);
  };
  const handleChangeRowsPerPage = (event) => {
    const limit = +event.target.value;
    const offset = 0;
    setRowsPerPage(limit);
    setPage(offset);
    handleClickedHeader(-1, offset, limit);
    localStorage.setItem(LOCAL_KEY_PAGE, offset);
    localStorage.setItem(LOCAL_KEY_ROWS_PER_PAGE, limit);
  };
  const [dataUser, setDataUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const offset = page;
    const limit = rowsPerPage;
    handleClickedHeader(-1, offset, limit);
  }, []);
  const colsHeaderNames = [
    { title: "Name", fieldName: "name" },
    { title: "Description", fieldName: "description" },
  ];
  const [colsIndex, setColsIndex] = useState(-1);
  const [reverseSort, setReverseSort] = useState(false);
  const [lastSortKey, setLastSortKey] = useState(colsHeaderNames[0].fieldName);
  const checkReverse = (index) => {
    let reverse = reverseSort;
    if (index === colsIndex) {
      reverse = !reverse;
    } else {
      reverse = false;
    }
    setReverseSort(reverse);
    return reverse;
  }
  const handleClickedHeader = (index, offset, limit) => {
    const reverse = index > -1 ? checkReverse(index) : reverseSort;
    let key = lastSortKey; // "active";
    setColsIndex(index);
    // name lastSeen distribution version
    if (0 < index && index < colsHeaderNames.length) {
      key = colsHeaderNames[index].fieldName;
      setLastSortKey(key);
    }
    const totalOffset = offset * limit;
    apiCall(key, reverse, totalOffset, limit);
  }
  const apiCall = async (key, reverse, totalOffset, limit) => {
    setIsLoading(true);
    try {
      const rolesDataAndCount = await GetRolesSorting(key, reverse, totalOffset, limit);
      const rolesData = rolesDataAndCount.data;
      const rolesCount = rolesDataAndCount.count;
      const transformedDataAry = transformedDataArrAction(rolesData);
      setTotalCount(rolesCount)
      setDataUser(transformedDataAry);
    }
    catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status < 400) { return; }
      props.logout();
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Card>
      <CardContent>
        {isLoading ?
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GradientCircularProgress ></GradientCircularProgress>
          </Box> :
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 10, padding: 0 }}>
                      <Box sx={{ width: 10 }}>
                      </Box>
                    </TableCell>
                    {colsHeaderNames.map((colItem, index) => (
                      <TableCell
                        // align={colItem.align}
                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => handleClickedHeader(index, page, rowsPerPage)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Box>
                            {colItem.title}
                          </Box>
                          <Box sx={{ justifyItems: 'flex-end' }} >
                            {
                              colsIndex >= 0 && colsIndex === index ?
                                (reverseSort ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                                : null
                            }
                          </Box>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody >
                  {dataUser.map((row) => (
                    <Row key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>}
      </CardContent>
    </Card>
  );
}

export default Roles;
