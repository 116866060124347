// App.js
import Navigations from "./Navigations";
import './index.css';

// 1. Import React Query modules
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// 2. Create a query client
const queryClient = new QueryClient();

function App() {
  return (
    // 3. Wrap your app with QueryClientProvider
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <Navigations />
      </div>
    </QueryClientProvider>
  );
}

export default App;

