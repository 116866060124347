
import React, { useState, useEffect } from 'react';

import {
    Box,
} from "@mui/material";


import { GetLastestsResults } from './sideBarResuqest';
import Badge from '@mui/material/Badge';
import { useRef } from 'react';


import {
    Build as ExecutionIcon,
} from "@mui/icons-material";




const myMap = new Map();


function generateKey(client_id, cmd_id) {
    return `${client_id}_${cmd_id}`;
}




const ExecutionWithDotBadge = () => {
    const [invisible, setInvisible] = useState(false);
    const [counter, setCounter] = useState(0); 
    const counterRef = useRef(0); 
    const intervalRef = useRef(null); 

    const apiChecksCall = async () => {
        console.log(" -> ping apiChecksCall");

        try {
            const respData = await GetLastestsResults(); 
            const data = respData;

            let flag = false; 

            data.forEach(item => {
                const key = generateKey(item.clients_id, item.cmds_id); 
                const mapValue = myMap.get(key);

                if (mapValue !== undefined) {
                    if (mapValue === true && item.verified === false) {
                        flag = true;
                    }
                } else {
                    if (item.verified === false) {
                        flag = true;
                    }
                }

                myMap.set(key, item.value);
            });

            setInvisible(flag);

        } catch (error) {
            console.log("API error:", error);
        }
    };

    useEffect(() => {
        const incrementCounterAndCheckApi = () => {
            counterRef.current += 1;
            console.log("Timer tick:", counterRef.current);
            setCounter(counterRef.current); 

            apiChecksCall();
        };

        if (!intervalRef.current) {
            incrementCounterAndCheckApi();
            intervalRef.current = setInterval(incrementCounterAndCheckApi, 25_000); 
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    return (
        <Box sx={{ color: 'action.active' }}>
            <Badge color="primary" variant="dot" invisible={invisible}>
                <ExecutionIcon />
            </Badge>
        </Box>
    );
};

export { ExecutionWithDotBadge };
