import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function ColumnSelectorPopover({ columns, onColumnsChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    const initialVisibility = columns.reduce((acc, col) => {
      acc[col.fieldName] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
  }, [columns]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (fieldName) => {
    setColumnVisibility((prev) => {
      const updated = { ...prev, [fieldName]: !prev[fieldName] };
      onColumnsChange(
        Object.keys(updated).filter((key) => updated[key])
      );
      return updated;
    });
  };

  const handleSelectAllChange = (checked) => {
    const updatedVisibility = columns.reduce((acc, col) => {
      acc[col.fieldName] = checked;
      return acc;
    }, {});
    setColumnVisibility(updatedVisibility);
    onColumnsChange(checked ? columns.map((col) => col.fieldName) : []);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'column-selector-popover' : undefined;

  return (
    <div style={{ width: 15, padding: 0 }}>

      <IconButton variant="outlined" size="small" color="primary" onClick={handleClick}>
        <FilterListIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

        <div>
          <Typography variant="h6" gutterBottom>
            Select Columns
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={Object.values(columnVisibility).every((val) => val)}
                indeterminate={
                  Object.values(columnVisibility).some((val) => val) &&
                  !Object.values(columnVisibility).every((val) => val)
                }
                onChange={(e) => handleSelectAllChange(e.target.checked)}
              />
            }
            label="Select All"
          />
          {columns.map((col) => (
            <FormControlLabel
              key={col.fieldName}
              control={
                <Checkbox
                  checked={columnVisibility[col.fieldName] || false}
                  onChange={() => handleCheckboxChange(col.fieldName)}
                />
              }
              label={col.title}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
}
