// import * as React from 'react';
import React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { activeBarColor, notActiveBarColor } from '../../Shared/ThemesColor';

import Box from '@mui/material/Box';


const size = {
  // width: 420,
  width: 480,
  height: 340,
};

export default function PieArcLabel(props) {

  const numA = props.totalVerifiedClients;
  const numB = props.totalUnVerifiedClients;
// Client Compliancy
  const labelActiveV1 = `compliant (${numA})`;
  const labelNotActiveV1 = `non compliant (${numB})`;

  const activeHosts = { value: numA, label: labelActiveV1, color: activeBarColor };
  const notActiveHosts = { value: numB, label: labelNotActiveV1, color: notActiveBarColor };

  const data = [
    activeHosts,
    notActiveHosts,
  ];

  return (
    <>
      <Box sx={{ width: 500, height: 0 }} >


        <Box sx={{ display: 'flex', justifyContent: "left", marginLeft: 12 }} >
          <h2>
            Client Compliancy
          </h2>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: "center" }} >
          <PieChart
            series={[
              {
                // arcLabel: (item) => `${item.label} (${item.value})`,
                arcLabel: (item) => `(${item.value})`,
                arcLabelMinAngle: 45,
                data: data,
                innerRadius: 30,
                outerRadius: 120,
                paddingAngle: 0,
                cornerRadius: 5,
                startAngle: 180,
                endAngle: 360 + 180,
                cx: 150,
                cy: 150,

              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontWeight: 'bold',
              },
            }}
            {...size}
          />
        </Box>
      </Box>

    </>
  );
}
