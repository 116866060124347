const activeBarColor = '#a7d1c4';
const notActiveBarColor = '#ffb3c3';
const lavenderBlush = '#FFF5FD';
const bakerMillerPink = '#FF96AD';
const mediumElectronicBlue = '#005A8D';
const prussianBlue = '#acc3db';

const ColoringValidation = (compliancy) => {
    return {
        width: '7px',
        height: '40px',
        backgroundColor: compliancy ? activeBarColor : notActiveBarColor,
    }
}

const ColoringValidationSettings = (verified, inactive_status_color = '#CCC') => {
    let color = inactive_status_color;
    if (verified === true) {
        color = activeBarColor;
    } else if (verified === false) {
        color = notActiveBarColor;
    }
    else if (verified === null) {
        color = inactive_status_color;
    }
    return {
        width: '7px',
        height: '40px',
        backgroundColor: color,
    }
}

export { ColoringValidationSettings };
export { ColoringValidation };
export { activeBarColor };
export { notActiveBarColor };
